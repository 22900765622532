import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Trans } from "react-i18next";
import { pointsUpdate } from "../../redux/actions";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
const EarnPointsPreview = (props) => {
	let searchParams = new URLSearchParams(useLocation().search);
	const id = searchParams.has("id") ? searchParams.get("id") : "";
	useEffect(() => {
		let data = {
			id: id,
			state: "completed",
		};
		if (id !== "") {
			props
				.pointsUpdate(data, "put")
				.then((responseData) => {
					//console.log("data", responseData);
				})
				.catch((err) => {
					console.log("Response Error:", JSON.stringify(err));
				});
		}
		//eslint-disable-next-line
	}, []);

	return (
		<div className="earnpoint-page">
			<Container fluid>
				<h2 className="alignCenter thankuh2">
					<Trans>Thank you for taking our quiz</Trans>!
				</h2>
				<h3 className="alignCenter">
					<Trans>You can only earn points for participating in this quiz once</Trans>.
				</h3>
			</Container>
		</div>
	);
};

export default connect(null, { pointsUpdate })(EarnPointsPreview);
