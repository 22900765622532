import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Trans, useTranslation } from "react-i18next";
import RichTextEditor from "../RichTextEditor";
import { ticketCreateAction } from "../../redux/actions";
import { toast } from "react-toastify";

const NewTicket = (props) => {
	const { t } = useTranslation();
	const [formdata, setFormdata] = useState({ subject: "", description: "" });
	const changeHandler = (key, value) => {
		let data = { ...formdata };
		data[key] = value;
		setFormdata(data);
	};
	const createTicketHandler = () => {
		props.setLoading(true);
		let requestBody = { subject: formdata.subject, message: formdata.description };
		ticketCreateAction(requestBody)
			.then((response) => {
				if (response.data) {
					props.parentCallback(true, response.data);
					toast.success(`${t("Ticket")} ${t("created successfully")}`);
				} else {
					toast.error(`${t("Error")}: ${t("Something went wrong")}, ${t("Please try again")}`);
				}
				props.setLoading(false);
			})
			.catch((ex) => {
				toast.error(`${t("Error")}: ${t("Something went wrong 1")}, ${t("Please try again")}`);
				console.log(ex);
				props.setLoading(false);
			});
	};
	return (
		<Container>
			<Row xs={1} md={1}>
				<Col>
					<h2 className="heading2 text-center">
						<Trans>New</Trans> <Trans>Support</Trans> <Trans>Ticket</Trans>
					</h2>
				</Col>
			</Row>
			<Row xs={1} md={1}>
				<Col>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>
								<Trans>Subject</Trans> <span style={{ color: "red", fontWeight: "bolder" }}> *</span>
							</Form.Label>
							<Form.Control type="text" name="subject" id="subject-textbox" onChange={(e) => changeHandler("subject", e.target.value)} value={formdata.subject} placeholder={t("Subject")} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>
								<Trans>Description</Trans> <span style={{ color: "red", fontWeight: "bolder" }}> *</span>
							</Form.Label>
							<RichTextEditor parentCallback={changeHandler} id="description" />
						</Form.Group>
					</Form>
				</Col>
			</Row>
			<Row xs={2} md={2}>
				<Col className="text-right">
					<Button onClick={createTicketHandler} disabled={formdata.subject && formdata.description ? false : true} variant={formdata.subject && formdata.description ? "primary" : "secondary"} id="new-ticket-create-btn">
						<Trans>Create</Trans> <Trans>Ticket</Trans>
					</Button>
				</Col>
				<Col className="text-left">
					<Button onClick={(e) => props.parentCallback(false)} variant="warning" id="back-ticket-list-btn">
						<Trans>GO BACK</Trans>
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default IsLoadingHOC(NewTicket, "Loading....");
