import React from "react";
import UploadReceiptImage from "../../components/ValidatePurchase/UploadReceiptImage";
const UploadReceipt = (props) => {
    return (
        <>
            <UploadReceiptImage />
        </>
    );
}

export default UploadReceipt;
