import React from "react";
import PortalPointList from "../../components/PortalPointList";
import CarouselCustom from "../../components/CarouselCustom";
const EarnPoints = (props) => {
	return (
		<>
			<CarouselCustom />
			<PortalPointList pagesize={8} />
		</>
	);
};

export default EarnPoints;
