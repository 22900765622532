import React from "react";
import PointHistory from "../../components/PointHistory";
const PointsHistory = (props) => {
    return (
        <>
            <PointHistory />
        </>
    );
}

export default PointsHistory;