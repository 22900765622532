import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
const RouteChangeTracker = () => {
	ReactGA.set({ page: window.location.href });
	ReactGA.pageview(window.location.href);

	return <div></div>;
};

export default withRouter(RouteChangeTracker);
