import React, {Fragment} from "react";
import Logo from "./Logo";

const TopLeft = () => {
	return (
		<Fragment>
			<div className="top-left">
				<Logo/>
			</div>
		</Fragment>
	);
};

export default TopLeft;