import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

const TermsAndCondition = props => {
  return (
    <>
      <main id="main">
        <div className="container">
          <div className="row justify-content-md-center section-content" id="termsandcondition">
            <Col md={12}>
              <div className="text-center mt-4">
                <h3>The Military Commissaries - Holiday Joint Industry $15 Visa® $10 Visa® $5 Visa® Virtual Account Rewards - Terms and Conditions </h3>
                <p className="text-center">
                  <strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING OR PARTICIPATING IN The Military Commissaries Holiday Joint Industry $15 Visa® $10 Visa® $5 Visa®Virtual Account Rewards Offer - YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ALL TERMS INCORPORATED BY REFERENCE. </strong>
                </p>

                {/* {props.pdflink?<p className="mb-4 mt-2"><i>Please read carefully to ensure you agree and <a href="/public/assets/doc/P&G-Professional-Partner-Rewards-Terms-and-Conditions.pdf" target="_blank">save or print a copy</a> for your records.</i></p>:<p className="mb-4 mt-2"><i>Please read carefully to ensure you agree and save a copy for your records</i></p>} */}
              </div>
              <div className="mb-5 text-left">
                <p>
                  <strong>Offer Timing:</strong> The Military Commissary Holiday Promotion Buy any 15 get a $15 Visa® Virtual Account Rewards; Buy any 10 get a $10 Visa® Virtual Account Rewards and/or Buy any 5 get a $5 Visa® Virtual Account Rewards Offer (the “<strong>Offer</strong>”) begins on November 18, 2022 at 12:00 a.m. Eastern Time (“ET”) and ends December 31,, 2022 at 11:59 p.m. ET (the “<strong>Purchase Period</strong>”). All purchases must be made within the Purchase Period. All
                  receipts from the Purchase Period must be submitted by January 5, 2022 at 11:59 p.m. ET.
                </p>
                <p>
                  <strong>Eligibility:</strong> Must be a Military Patron, either active or retired, with the benefit of shopping at a ConUS Commissary. Must be a legal resident of the United States to include <b>Alaska, Hawaii and Puerto Rico</b>, and are eighteen (18) years of age or older as of the last day of the month prior to the date of Offer redemption. Employees of Defense Commissary Agency, Harthill Marketing Services and/or participating brands indicated (the “Sponsor”), its parents,
                  subsidiaries, divisions, affiliates, suppliers, distributors and advertising, promotional and judging agencies, and their immediate family members (spouses, parents, children, and siblings and their spouses) and household members of each (whether related or not), are not eligible to participate in the Offer. This Offer is void where taxed, prohibited, or restricted by law. All federal, state, and local laws and regulations apply. Federal, state and local taxes, if any, are the
                  sole responsibility of participants.
                </p>

                <h4>How to redeem a $15, $10, or $5 Visa® Virtual Account Reward (the “Reward”): </h4>
                <p>
                  <strong>(1) Purchase any 15, 10, or 5 participating products as listed in Appendix A below in one purchase transaction during the Purchase Period in store at any Commissary within the U.S, Alaska, and Hawaii and receive a $15, $10, or $5 Visa® Virtual Account Reward. </strong>
                </p>
                <p>
                  <strong>(2)</strong> Then, visit www.commissarypromo.com (the “<strong>Website</strong>”) and complete all required registration information, including uploading the image of your original itemized, store-identified, dated receipt(s) that contain(s) the participating products purchased during the Purchase Period (the “<strong>Receipt</strong>”). Receipt(s) may be in the form of a photo of a printed out receipt at time of purchase..{' '}
                  <strong>
                    Each receipt must contain at least 15, 10 or 5 participating products from the provided list as indicated on the in store Point of Sale and/or on Website to receive Reward. If participating more than once, <u>each receipt must indicate your 15, 10. Or 5 purchases</u>. Receipts submitted that include a combined total of the 15, 10, or 5 purchases will not be accepted.{' '}
                  </strong>
                </p>
                <p>All qualifying receipts must be submitted by January 5, 2022 at 11:59 p.m. ET. If the Receipt is illegible or is otherwise non-compliant with the Receipt requirements described herein, then a Reward will NOT be issued.</p>
                <p>
                  <strong>(3)</strong> Once your Receipt(s) evidencing your purchase of 15, 10 or 5 or more of participating products are submitted and verified by Sponsor, you will receive a confirmation email from Sponsor with a link to redeem your Reward (the “<strong>Confirmation Email</strong>”).
                </p>
                <p>
                  <strong>(4)</strong> Follow the instructions at the redemption link in your Confirmation Email and select your preferred type of Reward $15, $10, or $5 Visa® Virtual Account Prepaid Card). Upon completing the redemption form with all required information, Rewards will be emailed to the email address used in the redemption form within 1 to 2 business days. Return of any Reward as undeliverable will result in forfeiture of Reward. All $15, $10, or $5 Rewards may need to be used
                  by date specified and terms and conditions may apply to Rewards. All Rewards are subject to the terms and conditions of their respective issuers.
                </p>
                <h4>Conditions and Disclaimers:</h4>
                <p>
                  Rewards will be digital and emailed to the email address provided at time of registration. The Offer is valid only for consumer use, not for resale or for commercial or reseller accounts. The $15, $10, or $5 Reward cannot be substituted or exchanged for physical cash or any equivalent. $15, $10, or $5 Visa® Virtual Account Prepaid Card are the only forms of $15, $10, or $5 Rewards available. This Offer is not valid on past or pre-ordered purchases. This Offer is subject to
                  change or discontinuation without notice. Offer cannot be combined with, and is not valid with, any other rebate, offer, discount, promotion or program.
                </p>
                <p>Sponsor will be collecting personal data (name and email) about participants online, in accordance with its privacy policy. Please review Sponsor’s privacy policy https://www.commissarypromo.com/privacy statement-na. By participating in the Offer, you hereby agree to Sponsor’s collection and usage of your personal information and acknowledge that you have read and accepted Sponsor’s privacy policy.</p>
                <p>
                  Use of computer programs and other automatic means to participate in the Offer is prohibited. Any attempt by any participant to obtain more than the stated limit/number of Rewards by using multiple/different email addresses, identities, registrations and logins, or any other methods will void such Offer redemptions. Normal Internet access and usage/data charges imposed by your online/phone service may apply. Sponsor reserves the right to substitute the Reward with an alternate
                  Reward of equal or greater value in the event that the Reward becomes unavailable for any reason.
                </p>
                <p>
                  Redeemed Offer will be declared to be made by the authorized account holder of the e-mail address submitted at the time of redemption. "Authorized account holder" of an e-mail address is defined as the natural person who is assigned to an e-mail address by an Internet Access provider, Online Service provider, or other organization (e.g., business, educational institution etc.) that is responsible for assigning e-mail addresses for the domain associated with the submitted e-mail
                  address. In the event of a dispute regarding the identity of the person submitting a registration form, the registration will be deemed to be submitted by the person in whose name the e-mail account is registered. Offer redemptions generated by script or bot are not eligible. Sponsor is not responsible for any Offers that are returned as undeliverable.
                </p>
                <p>
                  The Sponsor further reserves the right to cancel, terminate or modify the Offer at any time for any reason, in Sponsor’s sole discretion and all Offers properly submitted prior to cancellation will be awarded. Sponsor and their respective parent, subsidiary and affiliate companies are not responsible for late, misdirected, or incomplete Offers; or computer system, phone line, hardware, software or program malfunctions; or other errors, failures or delays in computer
                  transmissions or network connections that are human or technical in nature. Sponsor and their respective parent, subsidiary and affiliate companies assume no responsibility or liability for damages, losses or injury resulting from use of the Offer or Reward.
                </p>
                <p>
                  By accepting the Offer, the participant agrees to release and hold harmless Sponsor, and its respective subsidiaries, affiliates, suppliers, distributors, sales broker, The Defense Commissary Agency, advertising/promotion agencies and each of their respective parent companies and each such company’s officers, directors, employees and agents (collectively, the “<strong>Released Parties</strong>”) from and against any claim or cause of action, including, but not limited to,
                  personal injury, death, or damage to or loss of property, arising, in whole or in part, directly or indirectly, out of participation in the Offer or receipt or use or misuse of the Reward.
                </p>
                <p>ALL REWARDS ARE AWARDED “AS IS” AND WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED (INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE), AND ALL SUCH WARRANTIES ARE HEREBY DISCLAIMED.</p>
                <p>All issues and questions concerning the construction, validity, interpretation, and enforceability of these Official Terms and Conditions, or the rights and obligations of participant and Sponsor in connection with the Offer, shall be governed by, and construed in accordance with, the substantive laws of the State of California without regard to California conflicts of laws principles. All participants consent to the jurisdiction and venue of the State of California.</p>
                <p>If any provision of these Terms and Conditions is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and Conditions and shall not affect the validity and enforceability of any remaining provisions.</p>
                <p>
                  These Terms and Conditions constitute the entire agreement between Offer participants and the Sponsor pertaining to the subject matter hereof and supersedes all prior or other arrangements, understandings, negotiations and discussions, whether oral or written. No waiver of any of the provisions of these official Terms and Conditions shall be deemed or shall constitute a waiver of any other provisions hereof (whether or not similar), nor shall waiver constitute a continuing
                  waiver unless otherwise expressly provided.
                </p>
                <p>Card is issued by MetaBank®, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash access or recurring payments. Can be used everywhere Visa debit cards are accepted. Card valid for up to 6 months; unused funds will forfeit after the valid thru date. Card terms and conditions apply.</p>
                <p className="text-left">
                  <strong>SPONSOR:</strong> The Sponsor of the Offer is Harthill Marketing Services, PO Box 22679, Rancho Santa Margarita CA 92688.
                </p>
                <p>
                  <strong>Appendix A </strong>
                </p>
                <div>Participating Products, All Varieties: </div>
                <ul>
                  <li>M&M’s–7.44oz.&larger</li>
                  <li>Greenies DogTreats</li>
                  <li>Jimmy Dean Fresh Sausage</li>
                  <li>Aidells Sausage</li>
                  <li>Ball Park Franks</li>
                  <li>Hillshire Farms Smoked Sausage</li>
                  <li>Hillshire Cocktail Smokies</li>
                  <li>Jimmy Dean Bacon</li>
                  <li>Maxwell House 24.5to31oz.</li>
                  <li>Maxwell House & Gevalia Pods, 12-32count</li>
                  <li>Kraft Mayonnaise, 20oz.or larger</li>
                  <li>Oscar Mayer Bacon, 12oz.or larger</li>
                  <li>Cracker Barrel Mac & Cheese, 14oz.</li>
                  <li>Kraft Deluxe Dinners or Velveeta</li>
                  <li>Shells & Cheese 10.1to14oz.</li>
                  <li>Miracle Whip, 19 oz or larger</li>
                  <li>Kraft & Velveeta Slices,10.7oz and larger</li>
                  <li>Cool Whip,16oz</li>
                  <li>Philadelphia Cream Cheese, 8 oz brick</li>
                  <li>Colliders Refrigerated Desserts, 7oz</li>
                  <li>Frito Lay–Tostitos Salsa</li>
                  <li>Frito Lay–Tostitos, all sizes</li>
                  <li>OREO Cookies</li>
                  <li>RITZ Crackers</li>
                  <li>Sour Patch Kids, 1.8 lb. bag</li>
                  <li>Swedish Fish, 1.8 lb. bag</li>
                  <li>Old Trapper Jerky - Old Fashioned, Peppered, Teriyaki, & Hot & Spicy, 10oz</li>
                  <li>Betty Crocker Baking - Cakes, Cookies, Brownies,Muffin Mixes</li>
                  <li>Betty Crocker Frosting</li>
                  <li>Big G Cereals-CHEX, Cheerios, Lucky Charms, Cinnamon Toast Crunch, Cocoa Puffs, Reese’s Puffs, Trix</li>
                  <li>Pillsbury Refrigerated Doughs-Crescent Rolls, Cinnamon Rolls, Cookies, Pizza & Pie C</li>
                </ul>
              </div>
            </Col>
          </div>
        </div>
      </main>
    </>
  );
};

export default TermsAndCondition;
