import React from "react";
import { useState, useEffect } from "react";
const Timer = (props) => {
	const { initialMinute = 0, initialSeconds = 0 } = props;
	const [minutes, setMinutes] = useState(initialMinute);
	const [seconds, setSeconds] = useState(initialSeconds);

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0 && !props.pauseTimer) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
					props.timeCompleted();
				} else if (!props.pauseTimer) {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	});

	return (
		<span className="text-right">
			{minutes === 0 && seconds === 0 ? (
				<h5 id="timer-mm-ss"> 00:00 </h5>
			) : (
				<h5 id="timer-mm-ss">
					{" "}
					{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
				</h5>
			)}
		</span>
	);
};

export default Timer;
