import React from "react";
import EligibleProductList from "../../components/EligibleProductList";
const EligibleProduct = (props) => {
	return (
		<>
			<EligibleProductList />
		</>
	);
};

export default EligibleProduct;
