import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_fr from "./translations/fr_language_bundle.json";
import common_es from "./translations/sp_language_bundle.json";
import common_en from "./translations/en_language_bundle.json";

var userLang = navigator.language || navigator.userLanguage;
//console.log(userLang);
let lang = "en";
let file = common_en;
switch (userLang) {
	case "en-GB":
		lang = "en";
		file = common_en;
		break;
	case "fr-CA":
		lang = "fr";
		file = common_fr;
		break;
	case "es":
		lang = "es";
		file = common_es;
		break;
	default:
		lang = "en";
}

//console.log("lang", lang);
const resources = {
	[lang]: {
		translation: JSON.parse(JSON.stringify(file)),
	},
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: lang,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
