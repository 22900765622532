import React from "react";
const ProgressBar = (props) => {
	const { bgcolor, completed } = props;

	const containerStyles = {
		height: 12,
		width: "100%",
		backgroundColor: "#ccc",
		marginTop: "-10px",
	};

	const fillerStyles = {
		height: "100%",
		width: `${completed}%`,
		backgroundColor: bgcolor,
		borderRadius: "inherit",
		textAlign: "right",
	};

	return (
		<div style={containerStyles}>
			<div style={fillerStyles}></div>
		</div>
	);
};

export default ProgressBar;
