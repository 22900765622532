import React from "react";
import Contests from "../../components/Contests";
const Contest = (props) => {
	return (
		<>
			<Contests pagesize={8} />
		</>
	);
};

export default Contest;
