import React from "react";
import { Row, Col } from "react-bootstrap";
class ParticipateInstructions extends React.Component {
	render() {
		return (
			<Row className="justify-content-md-center text-center section-content">
				<Col sm={12} className="mb-4">
					<h2 className="home-product-heading">Participating Products</h2>
					<ol className="home-product-list">
						<li>M&M’s, 7.44 oz. & larger</li>
						<li>Greenies Dog Treats</li>
						<li>Jimmy Dean Fresh Sausage</li>
						<li>Aidells Sausage</li>
						<li>Ball Park Franks</li>
						<li>Hillshire Farms Smoked Sausage</li>
						<li>Hillshire Cocktail Smokies</li>
						<li>Jimmy Dean Bacon</li>
						<li>Maxwell House, 24.5 to 31 oz.</li>
						<li>Maxwell House & Gevalia Pods, 12-32 count</li>
						<li>Kraft Mayonnaise, 20oz. or larger</li>
						<li>Oscar Mayer Bacon, 12oz. or larger</li>
						<li>Cracker Barrel Mac & Cheese, 14oz.</li>
						<li>Kraft Deluxe Dinners or Velveeta</li>
						<li>Shells & Cheese, 10.1 to 14oz.</li>
						<li>Miracle Whip, 19 oz or larger</li>
						<li>Kraft & Velveeta Slices, 10.7oz and larger</li>
						<li>Cool Whip, 16oz</li>
						<li>Philadelphia Cream Cheese, 8 oz brick</li>
						<li>Colliders Refrigerated Desserts, 7oz</li>
						<li>Frito Lay - Tostitos Salsa</li>
						<li>Frito Lay - Tostitos, all sizes</li>
						<li>OREO Cookies</li>
						<li>RITZ Crackers</li>
						<li>Sour Patch Kids, 1.8 lb. bag</li>
						<li>Swedish Fish, 1.8 lb. bag</li>
						<li>Old Trapper Jerky - Old Fashioned, Peppered, Teriyaki, & Hot & Spicy, 10oz</li>
						<li>Betty Crocker Baking - Cakes, Cookies, Brownies, Muffin Mixes</li>
						<li>Betty Crocker Frosting</li>
						<li>Big G Cereals - CHEX, Cheerios, Lucky Charms, Cinnamon Toast Crunch, Cocoa Puffs, Reese’s Puffs, Trix</li>
						<li>Pillsbury Refrigerated Doughs - Crescent Rolls, Cinnamon Rolls, Cookies, Pizza & Pie Crust</li>

					</ol>
				</Col>
				<Col md={9} id="participate-content" aria-label="content">
					<h1>How To Participate</h1>
				</Col>
				<Col md={9}>
					
					<h2>
						1. BUY
					</h2>
					<p>
						Purchase 15, 10 or 5 participating products between November 18, 2022  and December 31, 2022.
					</p>
					
					<h2>
						2. TAKE A PICTURE
					</h2>
					<p>
						<i>Don’t forget that the photo of each receipt must display the Commissary Name and date of the transaction and the qualifying products purchased.</i> If the receipt is too long, please fold the receipt so that the Commissary, the date, and qualifying purchases are included in the picture.
					</p>

					<h2>
						3. REGISTER & UPLOAD
					</h2>
					<p>
						To register, please click ‘REGISTER’ on the navigation menu above. Once you complete registration, you will be prompted to upload your receipt. Look out for an email confirming your receipt was uploaded and received. Receipts can take up to two business days to process and will be subject to verification before approval is received, so please be patient. All eligible receipts MUST be uploaded by January 1, 2023.
					</p>
					<div className="mt-5">
						<a className="btn btn-primary" href="/register">REGISTER NOW</a>
					</div>
				</Col>
			</Row>
		);
	}
}

export default ParticipateInstructions;
