import React from "react";

const AboutUs = () => {
	return (
		<div>
         About Us page
		</div>
	);
};

export default AboutUs;