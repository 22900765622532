import React from "react";
import Contest from "../../components/contest";
const ContestXPreview = (props) => {
	return (
		<>
			<Contest urlType="public" />
		</>
	);
};

export default ContestXPreview;
