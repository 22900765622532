import { SAVE_USER_CONFIG, USER_POINT_BALANCE_SAVE, NOTIFICATION_CENTER_CREATE, NOTIFICATION_CENTER_UPDATE, NOTIFICATION_CENTER_POPUP_UPDATE, NOTIFICATION_CENTER_POPUP_CREATE, CURRENT_SELECTED_MENU } from "./types";
import api from "../../api/api.js";
import { menu } from "../../data";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// const talentBaseUrl = process.env.REACT_APP_TANENT_BASE_URL;

export const getContestAction = (pageNo, pageSize) => async (dispatch) => {
	try {
		//let limit = 4;
		const result = await api.get(`/s1/contest?page=${pageNo - 1}&limit=${pageSize}`, null);
		if (result.data) {
			return {
				viewMore: result.data.metaData.totalRecs > pageSize * pageNo ? true : false,
				data: result.data.content,
			};
		} else {
			return { viewMore: false, data: [] };
		}
	} catch (ex) {
		throw ex;
	}
};
export const getContestPreviewAction = (id, urlType) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		let result = null;
		if (urlType === "public") {
			result = await api.get(`/s1/contestx/spin/${id}`, {});
		} else {
			result = await api.get(`/s1/contest/${id}`, null);
		}
		if (result.httpCode === 200) {
			resolve(result);
		} else {
			reject(result.errors);
		}
	});
};
export const spinAction = (data, method, urlType) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		let result = {};
		if (method === "put") {
			if (urlType === "public") {
				result = await api.put(`/s1/contestx/spin/${data.token}`, data);
			} else {
				result = await api.put(`/s1/contest/spin/${data.parentId}`, data);
				dispatch(balanceGetAction());
				setTimeout(() => {
					dispatch(balanceGetAction());
				}, 5000);
			}
		} else {
			if (urlType === "public") {
				result = await api.post(`/s1/contestx/spin/${data.token}`, data);
			} else {
				result = await api.post(`/s1/contest/spin`, data);
				dispatch(balanceGetAction());
				setTimeout(() => {
					dispatch(balanceGetAction());
				}, 5000);
			}
		}
		if (result.httpCode >= 200 && result.httpCode < 300) {
			resolve(result);
		} else {
			reject(result);
		}
	});
};
export const pointHistoryGet = () => async (dispatch) => {
	try {
		//const data = await api.get(`/s2/points/0/5000`, null, 2);
		const data = await api.get(`/s1/points/points-history?page=0&limit=5000`, null, 2);
		if (data) {
			dispatch(balanceGetAction());
			return data;
		} else {
			throw new Error("Record not found!");
		}
	} catch (ex) {
		throw ex;
	}
};

export const balanceGetAction = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.get("/s2/points/balance", null);
		if (result.httpCode === 200) {
			resolve(result);
			dispatch({
				type: USER_POINT_BALANCE_SAVE,
				payload: result.data.pointBalance,
			});
		} else {
			if (typeof result === "object") {
				if (result["response"]) {
					reject(result["response"].data.errors);
				} else {
					reject([]);
				}
			} else {
				reject([]);
			}
		}
	});
};

export const getManu = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		resolve(menu);
	});
};

export const uiconfig = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		//axios.get(`${baseUrl}/tenant/testTenant2`)
		axios
			.post(`${baseUrl}/s1/campaign/mini`, "")
			.then(function(result) {
				// handle success
				if (result.data.httpCode === 200) {
					resolve(result.data);
					dispatch({ type: SAVE_USER_CONFIG, payload: result.data.data });
				} else {
					reject("error");
				}
			})
			.catch(function(error) {
				// handle error
				reject("error");
			});
	});
};

export const refreshToken = (token) => {
	axios
		.port(`${baseUrl}/s2/auth/refreshtoken`, {
			refreshToken: "eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjY5Njk2NjcsImV4cCI6MTYyNzAxMzY2N30.PPRCpcYkDMGMnHU1ci3s5GSeSnBkQ1oUzg87zMmRuso",
		})
		.then(function(response) {
			console.log(response);
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const uploadImageAction = (data) => {
	//return api.post(`/s2/receipt`, data);
	return api.post(`/s1/receipt/upload`, data);
};
export const validateUploadReceiptAction = (data) => {
	return api.post(`/s1/user/pre-validation?type=CHECK_REWARD_CLAIMED`, data);
};
export const validateCodeAction = (data) => {
	return api.post(`/s1/package/validatecode`, data);
};
export const eligibleProductListAction = () => {
	return api.get(`/s1/product?limit=5000`, null);
};
export const notificationStatusUpdateAction = (uuid, data) => {
	return api.put(`/s1/notification/${uuid}`, data);
};
export const notificationGetAction = (date = "") => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		let apiurl = "/s1/notification?page=0&limit=5000";
		if (date !== "") {
			apiurl = `${apiurl}&date=${date}`;
		}
		const result = await api.get(apiurl, null);
		if (result.httpCode === 200) {
			if (result.data.content) {
				let notifications = result.data.content.filter((v) => v.type === "web");
				let popupNotifications = result.data.content.filter((v) => v.type === "popup" && !v.isRead);
				let notificationList = notifications.map((v) => ({ shortMsg: v.richTextMsg, shortMessage: v.shortMessage, url: v.route ? v.route : "", time: v.createdAt, isRead: v.isRead, uuid: v.UUID, type: v.type }));
				let popupNotificationList = popupNotifications.map((v) => ({ shortMsg: v.richTextMsg, shortMessage: v.shortMessage, url: v.route ? v.route : "", time: v.createdAt, isRead: v.isRead, uuid: v.UUID, type: v.type }));
				if (date !== "") {
					dispatch({
						type: NOTIFICATION_CENTER_UPDATE,
						payload: notificationList,
					});
					dispatch({
						type: NOTIFICATION_CENTER_POPUP_UPDATE,
						payload: popupNotificationList,
					});
				} else {
					dispatch({
						type: NOTIFICATION_CENTER_CREATE,
						payload: notificationList,
					});
					dispatch({
						type: NOTIFICATION_CENTER_POPUP_CREATE,
						payload: popupNotificationList,
					});
				}
			}
			resolve(result);
		} else {
			if (date === "") {
				dispatch({
					type: NOTIFICATION_CENTER_CREATE,
					payload: [],
				});
			}
			if (typeof result === "object") {
				if (result["response"]) {
					reject(result["response"].data.errors);
				} else {
					reject([]);
				}
			} else {
				reject([]);
			}
		}
	});
};

export const ticketsAction = (id = "") => {
	if (id === "") {
		return api.get(`/s1/support/ticket?page=0&limit=5000`, null);
	} else {
		return api.get(`/s1/support/ticket/${id}`, null);
	}
};
export const ticketCreateAction = (body) => {
	return api.post(`/s1/support/ticket`, body);
};
export const receiptListAction = () => {
	return api.get(`/s1/userReceipts?page=0&limit=5000`, null);
};
export const helpQueryAction = (data) => {
	return api.post(`/s1/support/oreq`, data);
};
export const saveSelectedMenu = (data) => ({
	type: CURRENT_SELECTED_MENU,
	payload: data,
});
export const getLockupAction = (data) => {
	return api.post("/s1/lookup", data);
};
