import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { confirmAccount } from "../../redux/actions";
import { connect } from "react-redux";
const ConfirmAccount = ({ setLoading, confirmAccount, location }) => {
	const history = useHistory();
	const [confirm, setConfirm] = useState(false);
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get("token");
		setLoading(true);
		confirmAccount(token)
			.then((response) => {
				//console.log(response);
				setLoading(false);
				setConfirm(true);
				toast.success(response.message);
				history.push("/login");
			})
			.catch((error) => {
				console.log(error);
				toast.error(error);
				setLoading(false);
				setConfirm(false);
			});
		// eslint-disable-next-line
	}, []);

	return (
		<div className="resetpassword-page theme-reset-password-custom">
			<div className="row justify-content-center">
				<div className="col-lg-8 col-md-10">
					{confirm ? (
						<h1 className="main-heading" id="confirm-account-h1">
							Account Confirmed you can login now
						</h1>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default connect(null, { confirmAccount })(IsLoadingHOC(ConfirmAccount, "Wait ...."));
