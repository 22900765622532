import React from "react";
import RewardsPrev from "../../components/rewards/RewardsPrev";
class RewardsPreview extends React.Component {
	render() {
		return (
			<>
				<RewardsPrev />
			</>
		);
	}
}

export default RewardsPreview;
