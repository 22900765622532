import React, { useState } from "react";
import { useParams, Redirect, Link } from "react-router-dom";
import { pointsUpdate } from "../../redux/actions";
import ProgressBar from "../ProgressBar";
import { Container, Col, Row } from "react-bootstrap";
import YouTube from "react-youtube";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connect } from "react-redux";
const WatchVideo = (props) => {
	const { t } = useTranslation();
	const activityData = props.activityData;
	const [pageRedirect, setPageRedirect] = useState(false);
	const [watchStatus, setWatchStatus] = useState({ status: false, playbackevtid: "" });
	const [progress, setProgress] = useState(0);
	let { id } = useParams();
	const youtube_parser = (url) => {
		// eslint-disable-next-line
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		var match = url.match(regExp);
		if (match && match[7].length === 11) {
			var b = match[7];
			return b;
		} else {
			return false;
		}
	};
	activityData["data"] = activityData["data"].indexOf("http") >= 0 ? youtube_parser(activityData["data"]) : activityData["data"];
	const vedioPlayerOpts = {
		height: "360",
		width: "100%",
		playerVars: {
			rel: 0,
			autoplay: 0,
			controls: 0,
			disablekb: 1,
			enablejsapi: 1,
			fs: 1,
			origin: window.location.origin,
			playsinline: 1,
			widgetid: 1,
		},
	};
	const playerOnReady = (player) => {
		let total = player.target.getDuration();
		let time = player.target.getCurrentTime();
		let playerTimeDifference = (time / total) * 100;
		setProgress(playerTimeDifference);
	};
	const playerOnStateChange = (event) => {
		if (event.data === 1) {
			let total = event.target.getDuration();
			setInterval(function() {
				let time = event.target.getCurrentTime();
				let playerTimeDifference = (time / total) * 100;
				setProgress(playerTimeDifference);
			});
		}
	};
	const videoOnplay = (event) => {
		if (!watchStatus.status) {
			setWatchStatus(Object.assign({}, watchStatus, { status: true }));
			let data = {
				reference: id,
				state: "started",
			};

			props
				.pointsUpdate(data, "post", "view")
				.then((responseData) => {
					if (responseData["data"]) {
						setWatchStatus(Object.assign({}, watchStatus, { status: true, playbackevtid: responseData.data.id }));
					} else {
						setWatchStatus(Object.assign({}, watchStatus, { status: false, playbackevtid: "none" }));
					}
				})
				.catch((err) => {
					console.log("Response Error:", JSON.stringify(err));
					setWatchStatus(Object.assign({}, watchStatus, { status: false, playbackevtid: "none" }));
				});
		}
	};
	const videoEnd = (event) => {
		let data = {
			id: watchStatus.playbackevtid,
			state: "completed",
		};
		if (watchStatus.playbackevtid !== "") {
			props
				.pointsUpdate(data, "put", "view")
				.then((responseData) => {
					if (responseData["data"]) {
						toast.success(`${t("Congratulations")}! ${t("You have earned")} ${activityData.points} ${t("Points")}`);
					} else {
						toast.error(`${t("Sorry")}! ${t("You've earned the maximum points possible for this action")}`);
					}
					setPageRedirect(true);
				})
				.catch((err) => {
					if (watchStatus.playbackevtid === "none") {
						toast.error(`${t("Sorry")}! ${t("You've earned the maximum points possible for this action")}`);
					} else {
						toast.error(t("Something went wrong"));
					}
					console.log("Response Error:", JSON.stringify(err));
					setPageRedirect(true);
				});
		} else {
			setPageRedirect(true);
			toast.error(`${t("Sorry")}! ${t("You've earned the maximum points possible for this action")}`);
		}
	};

	if (pageRedirect) {
		return <Redirect to="/" />;
	} else {
		return (
			<>
				<div className="portal-point-list-container">
					<Container fluid>
						<div className="label">
							<Link id="headerParentLink" className="parent-link" to="/earnpoints">
								<Trans>Earn Points</Trans>
							</Link>{" "}
							<b>{activityData["title"] ? activityData.title : ""}</b>
						</div>
					</Container>
					<Container fluid>
						<Row xs={1} md={2} lg={2}>
							<Col>
								<YouTube id="youtube-iframe" onEnd={videoEnd} onPlay={videoOnplay} videoId={activityData["data"]} opts={vedioPlayerOpts} onReady={playerOnReady} onStateChange={playerOnStateChange} />
								<ProgressBar completed={progress} bgcolor="rgb(165 10 10)" />
								<h5 className="earn-point-label uppercase alignCenter">
									<Trans>WATCH AND EARN</Trans> {activityData["points"] ? activityData.points : 0} <Trans>Points</Trans>
								</h5>
							</Col>
							<Col>
								<h5 className="h5title">
									<Trans>Watch and Earn Points</Trans>
								</h5>
								<h4 className="title">
									<b>{activityData["title"] ? activityData.title : ""}</b>
								</h4>
								<p className="description">{activityData["description"] ? activityData.description : ""}</p>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
};
export default connect(null, { pointsUpdate })(WatchVideo);
