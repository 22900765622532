import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import IsLoadingHOC from "./../IsLoadingHOC";
import { receiptListAction } from "./../../redux/actions";
import TableReport from "./../TableReport";
const PreviousReceiptList = (props) => {
	const { t } = useTranslation();
	const [tableList, setTableList] = useState([]);
	const columns = [
		{ dataField: "date", text: t("Date Submitted") },
		{ dataField: "status", text: t("Receipt status") },
		{ dataField: "qualifyingAmount", text: "Amount Spent" },
	];
	useEffect(() => {
		props.setLoading(true);
		receiptListAction()
			.then((responseData) => {
				if (responseData.httpCode === 200 && responseData.data.content) {
					const respData = responseData.data.content.map((v) => {
						let itemDate = new Date(v.date).toLocaleDateString();
						let d = JSON.parse(JSON.stringify(v));
						d.date = itemDate;
						return d;
					});
					respData.sort((a, b) => {
						return new Date(b.date) - new Date(a.date);
					});

					let updatedRes = respData.map(res => !isNaN(res.qualifyingAmount) ? ({ ...res, qualifyingAmount: `$${res.qualifyingAmount}` }) : res )
					setTableList(updatedRes);
				} else {
					setTableList([]);
					console.log("responseData", responseData);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				props.setLoading(false);
				setTableList([]);
				console.log("Response Error:", JSON.stringify(err));
			});
		// eslint-disable-next-line
	}, [props.reloadCounter]);
	if (tableList.length > 0) {
		return (
			<Container fluid>
				<div className="validate-purchase-div">
					<Container fluid>
						<Row xs={1} md={1} lg={1}>
							<h4 className="h2-title" style={{ color: "#000", textDecoration: "underline" }}>
								<Trans>Previous Receipts Status</Trans>
							</h4>
						</Row>
						<Row>
							<Col xs lg="12" className="text-center">
								<TableReport searchTextboxEnable={false} keyField="id" data={tableList} columns={columns} />
							</Col>
						</Row>
					</Container>
				</div>
			</Container>
		);
	} else {
		return "";
	}
};

export default IsLoadingHOC(PreviousReceiptList, "Loading....");
