import React from "react";
import SubmitCode from "../../components/ValidatePurchase/SubmitCode";
const ValidateCode = (props) => {
    return (
        <>
            <SubmitCode />
        </>
    );
}

export default ValidateCode;