import React, { useEffect } from "react";

const WindowFocusHandler = (props) => {
	useEffect(() => {
		window.addEventListener("focus", props.tabOnFocus);
		window.addEventListener("blur", props.tabOnBlur);
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener("focus", props.tabOnFocus);
			window.removeEventListener("blur", props.tabOnBlur);
		};
	});

	return <></>;
};

export default WindowFocusHandler;
