import React, { useState, useEffect } from "react";
import DropIn from "braintree-web-drop-in-react";
import IsLoadingHOC from "../../IsLoadingHOC";
import { pgTokenAction, rewardsOrderPaymentAction, balanceGetAction } from "../../../redux/actions";
import { Container, Form, Button, Row, Col, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ViewOrder from "../../rewards/ViewOrder";
import { Link } from "react-router-dom";
const PaymentTransaction = (props) => {
	const [orderData, setOrderData] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let [instance, setInstance] = useState(null);
	let [paymentStatus, setPaymentStatus] = useState("");
	let [paymentStatusColor, setPaymentStatusColor] = useState("");
	let [clientToken, setClientToken] = useState(null);
	let [transaction, setTransaction] = useState(null);
	let [amount, setAmount] = useState(0);
	useEffect(() => {
		props.setLoading(true);
		setOrderData(props.data);
		setAmount(props.data.paymentAmount);
		pgTokenAction()
			.then((response) => {
				if (response.httpCode === 200) {
					setClientToken(response.data.token);
				} else {
					toast.error(`${t("Error")}: ${t("Error in Token generation")}!`);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				toast.error(`${t("Error")}: ${t("Error in Token generation")}!`);
				props.setLoading(false);
			});
		// eslint-disable-next-line
	}, []);
	const buy = async () => {
		if (!instance) {
			toast.error(`${t("Error")}: ${t("Please Wait")}!`);
			return false;
		}
		const { nonce } = await instance.requestPaymentMethod();
		if (!nonce) {
			toast.error(`${t("Error")}: ${t("Please Enter Payment details")}!`);
			return false;
		}
		props.setLoading(true);
		let requestData = { paymentMethodNonce: nonce };
		rewardsOrderPaymentAction(props.data.id, requestData)
			.then((response) => {
				if (response.httpCode === 200) {
					dispatch(balanceGetAction());
					let tempOrderData = JSON.parse(JSON.stringify(orderData));
					tempOrderData["transactionId"] = response.data.transactionId;
					tempOrderData["transactionDate"] = response.data.transactionDate ? new Date(response.data.transactionDate).toLocaleString() : "";
					tempOrderData["transactionAmount"] = amount;
					tempOrderData["transactionStatus"] = response.data.transactionStatus;
					if (response.data.transactionStatus) {
						if (response.data.transactionStatus.toUpperCase() === "SUCCESS") {
							setPaymentStatus("success");
							setPaymentStatusColor("success");
						} else if (response.data.transactionStatus.toUpperCase() === "PENDING") {
							setPaymentStatus("pending");
							setPaymentStatusColor("warning");
						} else {
							setPaymentStatus("failed");
							setPaymentStatusColor("danger");
						}
					}
					delete tempOrderData.paymentAmount;
					setOrderData(tempOrderData);
					toast.success(`${t("Your transaction has been successfully completed")}!`);
					setTransaction(true);
					props.setLoading(false);
				} else {
					toast.error(`${t("Error")}: ${t("Error in Payment Process")}!`);
					props.setLoading(false);
					setTransaction(true);
					let tempOrderData = JSON.parse(JSON.stringify(orderData));
					tempOrderData["transactionId"] = "";
					tempOrderData["transactionDate"] = new Date().toLocaleString();
					tempOrderData["transactionAmount"] = amount;
					tempOrderData["transactionStatus"] = "FAILED";
					setPaymentStatus("failed");
					setPaymentStatusColor("danger");
					delete tempOrderData.paymentAmount;
					setOrderData(tempOrderData);
				}
			})
			.catch((err) => {
				toast.error(`${t("Error")}: ${t("Error in Payment Process")}!`);
				console.log("Error", err);
				props.setLoading(false);
				setTransaction(true);
				let tempOrderData = JSON.parse(JSON.stringify(orderData));
				tempOrderData["transactionId"] = "";
				tempOrderData["transactionDate"] = new Date().toLocaleString();
				tempOrderData["transactionAmount"] = amount;
				tempOrderData["transactionStatus"] = "FAILED";
				setPaymentStatus("failed");
				setPaymentStatusColor("danger");
				delete tempOrderData.paymentAmount;
				setOrderData(tempOrderData);
			});
	};
	if (!clientToken) {
		return (
			<Container>
				<h1>
					<Trans>Loading</Trans>...
				</h1>
			</Container>
		);
	} else {
		return (
			<Container>
				{transaction ? (
					<Container>
						<Row>
							<Col md={10} lg={10} className="mx-auto mt-5">
								<Alert variant={paymentStatusColor}>
									<Alert.Heading style={{ textAlign: "center" }}>
										<Trans>Payment</Trans> <Trans>{paymentStatus}</Trans>!
									</Alert.Heading>
								</Alert>
								<ViewOrder data={orderData} />
							</Col>
						</Row>
						<Row xs={1} md={1}>
							<Col className="text-center">
								<Link variant="warning" id="back-btn" className="btn btn-warning" to="/rewards">
									<Trans>GO BACK</Trans>
								</Link>
							</Col>
						</Row>
					</Container>
				) : (
					<>
						<DropIn options={{ authorization: clientToken }} onInstance={(inst) => setInstance(inst)} />
						<Form>
							<Row>
								<Col sm={2} md={2}>
									<strong>
										<Trans>Payable</Trans> <Trans>Amount</Trans>
									</strong>
								</Col>
								<Col sm={8} md={8}>
									{props.data ? `${props.data.paymentAmount} ${props.data.currency}` : ""}
								</Col>
							</Row>
							<hr />
							<Row>
								<Col sm={2} md={2}></Col>
								<Col sm={8} md={8}>
									<Button className="cart-button btn btn-primary" onClick={buy}>
										<Trans>Make Payment</Trans>
									</Button>{" "}
								</Col>
							</Row>
						</Form>
					</>
				)}
			</Container>
		);
	}
};

export default IsLoadingHOC(PaymentTransaction, "Loading....");
