import api from "../../api/api.js";
export const rewardsListAction = (id = "") => {
	if (id === "") {
		return api.get(`/s2/rewards/campaign`, null);
	} else {
		return api.get(`/s2/rewards/campaign/${id}`, null);
	}
};
export const pgTokenAction = () => {
	return api.get(`/s2/pg/pgToken`, null);
};
export const rewardsOrderPaymentAction = (id, body) => {
	return api.put(`/s2/rewards/order/${id}/checkout`, body);
};
export const redeemRewardOrderAction = (id) => {
	return api.post(`/s2/rewards/campaign/redeem/${id}`, {});
};
export const rewardsOrderAddressUpdateAction = (id, body) => {
	return api.put(`/s2/rewards/order/address/${id}`, body);
};
export const downloadRewardsAction = (id, filename) => {
	return api.get(`/s2/rewards/order/download/${id}/${filename}`, null);
};
export const getMyRewardsAction = (pageNum = 0, pageSize = 5000) => {
	return api.get(`/s2/points/myreward/${pageNum}/${pageSize}`, null);
};
export const rewardsListUnSecureAction = () => {
	return api.post(`/s1/userportal/rewardlist`, {});
};
