import { combineReducers } from "redux";

import app from "./app";
import user from "./user";
import earnpoint from "./earnpointsReducer";
export default combineReducers({
	app,
	user,
	earnpoint,
});
