import React from "react";

const ContactUs = () => {
	return (
		<div>
         ContactUs page goes hereddd
		</div>
	);
};

export default ContactUs;