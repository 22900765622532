import React, { useState } from 'react';
import { Container, Row, Col, Accordion, Card, Button, Nav } from 'react-bootstrap';
const FrequentlyAskedQuestions = () => {
  const [data, setData] = useState([
    { id: 0, clicked: false, name: 'How long do I have to submit my receipt?', content: 'All products must be purchased between November 18, 2022 and December 31, 2022. You have January 1, 2023  at 11:59PM ET to upload your receipt.' },
    { id: 1, clicked: false, name: 'What do I do if I am having trouble participating in the program?', content: "You can submit any questions you may have about the promotion to our <a href='/support'>support box</a>." },
    {
      id: 2,
      clicked: false,
      name: 'What are the participating products?',
      content:
        'All Varieties:<ul><li>M&M’s, 7.44 oz. & larger</li><li>Greenies Dog Treats</li><li>Jimmy Dean Fresh Sausage</li><li>Aidells Sausage</li><li>Ball Park Franks</li><li>Hillshire Farms Smoked Sausage</li><li>Hillshire Cocktail Smokies</li><li>Jimmy Dean Bacon</li><li>Maxwell House, 24.5 to 31 oz.</li><li>Maxwell House & Gevalia Pods, 12-32 count</li><li>Kraft Mayonnaise, 20oz. or larger</li><li>Oscar Mayer Bacon, 12oz. or larger</li><li>Cracker Barrel Mac & Cheese, 14oz.</li><li>Kraft Deluxe Dinners or Velveeta</li><li>Shells & Cheese, 10.1 to 14oz.</li><li>Miracle Whip, 19 oz or larger</li><li>Kraft & Velveeta Slices, 10.7oz and larger</li><li>Cool Whip, 16oz</li><li>Philadelphia Cream Cheese, 8 oz brick</li><li>Colliders Refrigerated Desserts, 7oz</li><li>Frito Lay - Tostitos Salsa</li><li>Frito Lay - Tostitos, all sizes</li><li>OREO Cookies</li><li>RITZ Crackers</li><li>Sour Patch Kids, 1.8 lb. bag</li><li>Swedish Fish, 1.8 lb. bag</li><li>Old Trapper Jerky - Old Fashioned, Peppered, Teriyaki, & Hot & Spicy, 10oz</li><li>Betty Crocker Baking - Cakes, Cookies, Brownies, Muffin Mixes</li><li>Betty Crocker Frosting</li><li>Big G Cereals - CHEX, Cheerios, Lucky Charms, Cinnamon Toast Crunch, Cocoa Puffs, Reese’s Puffs, Trix</li><li>Pillsbury Refrigerated Doughs - Crescent Rolls, Cinnamon Rolls, Cookies, Pizza & Pie Crust</li></ul>',
    },
    { id: 3, clicked: false, name: 'How many times can I submit the same valid receipt?', content: 'You can only submit a valid receipt once, receipts submitted more than once will be rejected.' },
    { id: 4, clicked: false, name: 'How many rewards can I get?', content: 'There is no limit to the number of rewards per person/email address. Each receipt must be specific to the 15, 10, or 5 purchases made and submitted individually.' },
    { id: 5, clicked: false, name: 'Do I have to purchase all 15, 10 or 5 participating products in one transaction?', content: 'Yes, you must purchase participating products on one transaction between November 18, 2022  and December 31, 2022. The denomination of Visa you can receive will be based on how many products you purchase:<ul><li>Purchase 15 participating products get $15</li><li>Purchase 10 participating products get $10</li><li>Purchase 5 participating products get $5</li></ul> ' },
    { id: 6, clicked: false, name: 'When will I receive my reward?', content: 'Rewards are delivered in real time once the receipt has been approved..' },
    { id: 7, clicked: false, name: 'Why am I not receiving emails?', content: 'Please mark support@receiptprocessor.com as a safe sender and make sure to check your spam.' },
    { id: 8, clicked: false, name: 'What do I do if I have additional questions?', content: "Review the <a href='/termsandconditions' target=''>Terms and Conditions</a> for the offer for additional details. If the <a href='/termsandconditions' target=''>Terms and Conditions</a> have not answered your question, please email us via the <a href='/support'>support box</a>." },
  ]);
  const [faqStatus, setFaqStatus] = useState(false);
  const toggleHandler = i => {
    let tempData = JSON.parse(JSON.stringify(data));
    tempData[i].clicked = tempData[i].clicked ? false : true;
    setData(tempData);
  };
  const bulkActionHandler = () => {
    let status = !faqStatus;
    setFaqStatus(status);
    let tempData = data.map(v => ({ ...v, clicked: status }));
    setData(tempData);
  };
  return (
    <main id="main">
      <Container>
        <Row className="justify-content-md-center section-content faq" id="faq-content">
          <Col md={12}>
            <h1 className="section-heading text-center pb-3">Frequently Asked Questions</h1>
          </Col>
          <Col md={11} className="text-right my-3">
            <Button onClick={bulkActionHandler} variant="link">
              {!faqStatus ? 'Open All' : 'Close All'}
            </Button>
          </Col>
          <Col md={11}>
            <Accordion>
              {data.map((v, i) => {
                return (
                  <Card style={{ marginBottom: '1rem' }}>
                    <Card.Header>
                      <Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" onClick={e => toggleHandler(i)} eventKey={v.id}>
                        <Row style={{ cursor: 'pointer' }}>
                          <Col xs={11}>{v.name}</Col>
                          <Col xs={1} className="text-right pl-0 faq_open-close-icon">
                            {v.clicked ? '−' : '+'}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </Card.Header>
                    {v.clicked ? (
                      <Accordion>
                        <Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
                      </Accordion>
                    ) : (
                      <Accordion.Collapse>
                        <Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
                      </Accordion.Collapse>
                    )}
                  </Card>
                );
              })}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default FrequentlyAskedQuestions;
