import React from 'react';
import { useSelector } from 'react-redux';
import { carouselData } from '../data';

const CarouselCustom = () => {
  const user = useSelector(({ user }) => user);
  const config = useSelector(state => state.app.config);
  const [isActiveSite, setIsActiveSite] = React.useState(true);
  React.useEffect(() => {
    if (config.campaignState === 'partialExpired' || config.submissionEnded === 'submissionExpired') {
      setIsActiveSite(false);
    }
    //eslint-disable-next-line
  }, [config]);

  return (
    <div className="justify-content-center" role="banner">
      {carouselData.map((item, index) => {
        return (
          <>
            {isActiveSite ? (
              <a href="/register">
                <img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} aria-labelledby="banner-text" />
                <img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} aria-labelledby="banner-text" />
              </a>
            ) : (
              <>
                <img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} aria-labelledby="banner-text" />
                <img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} aria-labelledby="banner-text" />
              </>
            )}
          </>
        );
      })}
      <p id="banner-text" className="sr-only sr-only-focusable">
        Get a $15 Virtual Visa Prepaid card When you buy any 15 participating products or Get a $10 Virtual Visa Prepaid card When you buy any 10 participating products or Get a $5 Virtual Visa Prepaid card When you buy any 5 participating products. Between 11/18/22-12/31/22. No limit on the number of rewards you can earn... available while supplies last***
      </p>
    </div>
  );
};

export default CarouselCustom;
