import React from 'react';
import { getManu } from '../../redux/actions';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import CopyRight from './../CopyRight';
//import { useTranslation } from "react-i18next";
const Footer = () => {
  //const { t } = useTranslation();

  const handleShow = () => {
    if (window.Optanon) {
      window.Optanon.ToggleInfoDisplay();
    }
  };
  return (
    <footer>
      <Container fluid className="footer">
        <div className="footer-link" aria-label="footerMenu">
          <ul>
            <li>
              <a href="/termsandconditions">Terms & Conditions</a>
            </li>
            <li>|</li>
            <li>
              <a href="https://www.3tl.com/privacy" target="_blank">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="row justify-content-center footer-offer">
          <div className="col-12 text center">
            <p>
              Must be a Military Patron, either active or retired, with the benefit of shopping at a ConUS Commissary. Must be a legal resident of the United States to include Alaska, Hawaii and Puerto Rico, and 18 years of age or older as of the last day of the month prior to the date of Offer redemption. All purchases must be made between 12:00 a.m. ET on November 18th, 2022 and 11:59 p.m. ET on December 31st, 2022. Receipt must be submitted by 11:59 p.m. ET on January 1, 2023. There is no
              limit to the number of rewards per person/email address. Each receipt must be specific to the 15, 10, or 5 purchases made and submitted individually.
            </p>
            <p>Card is issued by MetaBank®, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash access or recurring payments. Can be used everywhere Visa debit cards are accepted. Card valid for up to 6 months after activated; unused funds will forfeit after the valid thru date. Card terms and conditions apply.</p>
          </div>
        </div>
        {/* <div className="footer-link">
					<ul>
						<li>
							<button className="btn" onClick={handleShow}>
								Do Not Sell My Personal Information
							</button>
						</li>
						<li>|</li>
						<li>
							<a href="https://www.pg.com/privacy/english/privacy_statement.shtml#iba" target="_blank" >
								AdChoices<img src="/public/assets/images/AdChoicesicon.png" alt="Adchoices logo" className="adchoices" />
							</a>
						</li>
					</ul>
				</div> */}
      </Container>
      <CopyRight />
    </footer>
  );
};
export default connect(null, { getManu })(Footer);
