import { USER_DATA_SAVE, LOG_OUT, SAVE_ACCESS_TOKEN, SAVE_REFRESH_TOKEN, USER_POINT_BALANCE_SAVE } from "../actions/types";
let initialState = {
	accessToken: "",
	name: "",
	refreshToken: "",
	pointBalance: 0,
};
export default function(state = initialState, action) {
	switch (action.type) {
		case USER_DATA_SAVE:
			return { ...state, ...action.payload };
		case SAVE_ACCESS_TOKEN:
			return { ...state, accessToken: action.payload };
		case SAVE_REFRESH_TOKEN:
			return { ...state, refreshToken: action.payload };
		case USER_POINT_BALANCE_SAVE:
			return { ...state, pointBalance: action.payload };
		case LOG_OUT:
			return { ...state, accessToken: "", refreshToken: "" };
		default:
			return state;
	}
}
