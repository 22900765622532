import React from "react";
import Support from "../../components/Support";
const SupportScreen = (props) => {
	return (
		<>
			<Support />
		</>
	);
};

export default SupportScreen;
