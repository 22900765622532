import jsPDF from "jspdf";
const A4_PAPER_DIMENSIONS = {
	width: 575,
	height: 812,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

export const imageDimensionsOnA4 = (dimensions) => {
	const isLandscapeImage = dimensions.width >= dimensions.height;

	if (isLandscapeImage) {
		return {
			width: A4_PAPER_DIMENSIONS.width,
			height: A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
		};
	}

	const imageRatio = dimensions.width / dimensions.height;
	if (imageRatio > A4_PAPER_RATIO) {
		const imageScaleFactor = (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

		const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

		return {
			height: scaledImageHeight,
			width: scaledImageHeight * imageRatio,
		};
	}

	return {
		width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
		height: A4_PAPER_DIMENSIONS.height,
	};
};

export const generatePdfFromImages = (images) => {
	return new Promise((resoved, reject) => {
		try {
			const doc = new jsPDF("p", "pt", "a4", true);
			doc.deletePage(1);
			images.forEach((image) => {
				let imageNewWidth = image.naturalWidth;
				let imageNewHeight = image.height;

				const imageDimensions = imageDimensionsOnA4({
					width: imageNewWidth,
					height: imageNewHeight,
				});

				doc.addPage();
				doc.addImage(image.src, image.fileType.split("/")[1].toUpperCase(), 10, 20, imageDimensions.width, imageDimensions.height, undefined, "FAST");
			});
			const pdfURL = doc.output("bloburl");
			fetch(pdfURL)
				.then((response) => response.blob())
				.then((data) => {
					let newFile = new File([data], `${new Date().getTime()}_generated.pdf`, { type: "application/pdf" });
					resoved(newFile);
				})
				.catch((err) => {
					reject(err);
				});
		} catch (err) {
			reject(err);
		}
	});
};
