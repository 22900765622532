import React from "react";
import TicketList from "../../components/help-desk/TicketList";
const HelpDesk = (props) => {
	return (
		<>
			<TicketList />
		</>
	);
};

export default HelpDesk;
