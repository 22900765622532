import React from "react";
import Contest from "../../components/contest";
const ContestPreview = (props) => {
	return (
		<>
			<Contest urlType="private" />
		</>
	);
};

export default ContestPreview;
