import React from "react";
import EarnPoints from "../../components/earn-point/EarnPoints";
const EarnPointsPreview = (props) => {
	return (
		<>
			<EarnPoints />
		</>
	);
};

export default EarnPointsPreview;
