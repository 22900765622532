import { USER_DATA_SAVE, LOG_OUT, SAVE_ACCESS_TOKEN, SAVE_REFRESH_TOKEN } from "./types";
import { balanceGetAction, notificationGetAction } from "./app";
// import {api} from "../../api/index";
import api from "../../api/api.js";
export const signup = (data, referralid = '') => async dispatch => {
  return new Promise(async (resolve, reject) => {
    let url = '/s2/auth/signup';
    if (referralid !== '') {
      url = `/s2/auth/signup?refCode=${referralid}`;
    }
    try {
      const result = await api.post(url, data);
      if (result.httpCode === 200) {
        resolve(result.message);
      } else if (result.httpCode === 206) {
        dispatch({type: USER_DATA_SAVE, payload: result.data});
        const {accessToken, refreshToken} = result.data;
        dispatch(saveAccessToken(accessToken));
        dispatch(saveRefreshToken(refreshToken));
        resolve(result);
      } else {
        if ('errors' in result && Array.isArray(result.errors)) {
          reject(result.errors);
        } else {
          if (typeof result === 'object') {
            if (result['response']) {
              reject(result['response'].data.errors);
            } else {
              reject([]);
            }
          } else {
            reject([]);
          }
        }
      }
    } catch (error) {
      reject([]);
    }
  });
};
export const login = (data) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post("/s2/auth/signin", data);
		if (result.httpCode === 200 && result.data) {
			resolve(result);
			dispatch({ type: USER_DATA_SAVE, payload: result.data });
			const { accessToken, refreshToken } = result.data;
			dispatch(saveAccessToken(accessToken));
			dispatch(saveRefreshToken(refreshToken));
			dispatch(balanceGetAction());
			dispatch(notificationGetAction());
		}
		if (result.httpCode === 204) {
			resolve(result);
			dispatch(saveAccessToken(""));
			dispatch(saveRefreshToken(""));
		} else {
			if (typeof result === "object") {
				reject(result);
			} else {
				if (typeof result === "object") {
					if (result["response"]) {
						reject(result["response"].data);
					} else {
						reject([]);
					}
				} else {
					reject([]);
				}
			}
		}
	});
};
export const secondFactorAuthAction = (data) => {
	return api.post("/s2/auth/verification ", data);
};

export const secondFactorAuthAction_old = (data) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post("/s2/auth/verification ", data);
		if (result.httpCode === 200 && result.data) {
			resolve(result);
			dispatch({ type: USER_DATA_SAVE, payload: result.data });
			const { accessToken, refreshToken } = result.data;
			dispatch(saveAccessToken(accessToken));
			dispatch(saveRefreshToken(refreshToken));
			dispatch(balanceGetAction());
			dispatch(notificationGetAction());
		} else {
			if (typeof result === "object") {
				reject(result);
			} else {
				if (typeof result === "object") {
					if (result["response"]) {
						reject(result["response"].data);
					} else {
						reject([]);
					}
				} else {
					reject([]);
				}
			}
		}
	});
};

export const googleLoign = (data) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post("/s2/auth/google", data);
		if (result.httpCode >= 200 && result.httpCode <= 299) {
			resolve(result);
			if (result.httpCode === 200) {
				dispatch({ type: USER_DATA_SAVE, payload: result.data });
				dispatch(balanceGetAction());
				dispatch(notificationGetAction());
			}
		} else {
			if (typeof result === "object") {
				if (result["response"]) {
					reject(result["response"].data.errors);
				} else {
					reject([]);
				}
			} else {
				reject([]);
			}
		}
	});
};
export const appleLoignAction = (data) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post("/s2/auth/apple", data);
		if (result.httpCode >= 200 && result.httpCode <= 299) {
			resolve(result);
			if (result.httpCode === 200) {
				dispatch({ type: USER_DATA_SAVE, payload: result.data });
				dispatch(balanceGetAction());
				dispatch(notificationGetAction());
			}
		} else {
			if (typeof result === "object") {
				if (result["response"]) {
					reject(result["response"].data.errors);
				} else {
					reject([]);
				}
			} else {
				reject([]);
			}
		}
	});
};

export const microsoftLogin = (data) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post("/s2/auth/microsoft", data);
		if (result.httpCode >= 200 && result.httpCode <= 299) {
			resolve(result);
			if (result.httpCode === 200) {
				dispatch({ type: USER_DATA_SAVE, payload: result.data });
				dispatch(balanceGetAction());
				dispatch(notificationGetAction());
			}
		} else {
			if (typeof result === "object") {
				if (result["response"]) {
					reject(result["response"].data.errors);
				} else {
					reject([]);
				}
			} else {
				reject([]);
			}
		}
	});
};

export const facebookLogin = (data) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post("/s2/auth/facebook", data);
		if (result.httpCode >= 200 && result.httpCode <= 299) {
			resolve(result);
			if (result.httpCode === 200) {
				dispatch({ type: USER_DATA_SAVE, payload: result.data });
				dispatch(balanceGetAction());
				dispatch(notificationGetAction());
			}
		} else {
			if ("errors" in result && Array.isArray(result.errors)) {
				reject(result.errors);
			} else {
				if (typeof result === "object") {
					if (result["response"]) {
						reject(result["response"].data.errors);
					} else {
						reject([]);
					}
				} else {
					reject([]);
				}
			}
		}
	});
};

export const forgetPassword = (data) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post(`/s2/identities/forgot-password?email=${data}`);
		if (result.httpCode === 200) {
			resolve(result);
		} else {
			if ("errors" in result && Array.isArray(result.errors)) {
				reject(result.errors);
			} else {
				if (typeof result === "object") {
					if (result["response"]) {
						reject(result["response"].data.errors);
					} else {
						reject([]);
					}
				} else {
					reject([]);
				}
			}
		}
	});
};
export const resetPasswordTokenValidationCheckAction = (token, data) => {
	return api.post(`/s2/identities/tokenvalidation?token=${token}`, data);
};

export const resetPassword = ({ token, password }) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post(`/s2/identities/reset-password?token=${token}`, { password: password });
		if (result.httpCode === 200 || result.httpCode === 202) {
			resolve(result);
		} else {
			if ("errors" in result && Array.isArray(result.errors)) {
				reject(result.errors);
			} else {
				if (typeof result === "object") {
					if (result["response"]) {
						reject(result["response"].data.errors);
					} else {
						reject([]);
					}
				} else {
					reject([]);
				}
			}
		}
	});
};

export const confirmAccount = (token) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post(`/s2/auth/confirm-account?token=${token}`, { token: token });
		if (result.httpCode === 200) {
			resolve(result);
		} else {
			if (typeof result === "object") {
				if (result["response"]) {
					reject(result["response"].data.errors);
				} else {
					reject([]);
				}
			} else {
				reject([]);
			}
		}
	});
};
export const logoutAction = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.post(`/s2/auth/logout`, {});
		dispatch({
			type: LOG_OUT,
		});
		resolve(result);
	});
};
export const logoutFromLocalAction = () => ({
	type: LOG_OUT,
});
export const saveAccessToken = (token) => ({
	type: SAVE_ACCESS_TOKEN,
	payload: token,
});

export const saveRefreshToken = (token) => ({
	type: SAVE_REFRESH_TOKEN,
	payload: token,
});

export const getIdentities = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.get("/s2/campaign");
		if (result.httpCode === 200) {
			resolve(result);
		} else {
			if ("errors" in result && Array.isArray(result.errors)) {
				reject(result.errors);
			} else {
				if (typeof result === "object") {
					if (result["response"]) {
						reject(result["response"].data.errors);
					} else {
						reject([]);
					}
				} else {
					reject([]);
				}
			}
		}
	});
};

export const getUserDetails = (id) => async (dispatch) => {
	//console.log("user id", id);
	return new Promise(async (resolve, reject) => {
		const result = await api.get(`/s2/identities/${id}`);
		if (result.httpCode === 200) {
			resolve(result);
			//dispatch({type: USER_DATA_SAVE, payload: result.data});
		} else {
			if ("errors" in result && Array.isArray(result.errors)) {
				reject(result.errors);
			} else {
				if (typeof result === "object") {
					if (result["response"]) {
						reject(result["response"].data.errors);
					} else {
						reject([]);
					}
				} else {
					reject([]);
				}
			}
		}
	});
};

export const updateUserDetails = (id, data) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.put(`/s2/identities/${id}`, data);
		if (result.httpCode === 200) {
			resolve(result);
		} else {
			if ("errors" in result && Array.isArray(result.errors)) {
				reject(result.errors);
			} else {
				if (typeof result === "object") {
					if (result["response"]) {
						reject(result["response"].data.errors);
					} else {
						reject([]);
					}
				} else {
					reject([]);
				}
			}
		}
	});
};

export const oAuthRegistrationAction = (data, updateId) => async (dispatch) => {
	const url = updateId === "facebook" ? "/s2/auth/facebook" : "/s2/auth/oauth";
	return new Promise(async (resolve, reject) => {
		const result = await api.put(url, data);
		if (result.httpCode >= 200 && result.httpCode <= 299) {
			resolve(result);
			dispatch({ type: USER_DATA_SAVE, payload: result.data });
			dispatch(balanceGetAction());
			dispatch(notificationGetAction());
		} else {
			if (typeof result === "object") {
				if (result["response"]) {
					reject(result["response"].data.errors);
				} else {
					reject([]);
				}
			} else {
				reject([]);
			}
		}
	});
};
export const preRegistrationAction = (data) => {
	return api.post("/s1/user/pre-register ", data);
};
