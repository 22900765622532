import { EARNPOINT_VIDEO_PREVIEW, EARNPOINT_VIDEO_PLAYSTATUS } from "./types";
import api from "../../api/api.js";
import { balanceGetAction } from "./app";
export const earnPointsPreview = (id) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.get(`/s1/activity/${id}`, null);
		if (result.httpCode === 200) {
			dispatch({ type: EARNPOINT_VIDEO_PREVIEW, payload: result.data });
			resolve(result);
		} else {
			if ("errors" in result && Array.isArray(result.errors)) {
				reject(result.errors);
			} else {
				reject([]);
			}
		}
	});
};

export const pointsUpdate = (data, type, activityType = "") => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		let result = {};
		if (type === "post") {
			if (activityType === "view") {
				dispatch({ type: EARNPOINT_VIDEO_PLAYSTATUS, payload: { ...data, activityType: activityType } });
			}
			result = await api.post(`/s2/points`, data);
		} else {
			if (activityType === "view") {
				dispatch({ type: EARNPOINT_VIDEO_PLAYSTATUS, payload: {} });
			}
			result = await api.put(`/s2/points`, data);
		}
		if (result["data"]) {
			resolve(result);
			if (type !== "post") {
				dispatch(balanceGetAction());
			}
		} else {
			reject(result);
		}
	});
};
export const referFriendsAction = (data) => {
	return api.post(`/s2/social/referral`, data);
};
export const portalPointAction = (id, actionType = [], limit = 4) => async (dispatch) => {
	try {
		//let limit = 4;
		const result = await api.get(`/s1/activity?page=${id - 1}&limit=${limit}&actiontype=${actionType.toString()}`, null, 2);
		if (result.data) {
			return {
				end: result.data.metaData.totalRecs > limit * id ? true : false,
				data: result.data.content,
			};
		} else {
			return { end: false, data: [] };
		}
	} catch (ex) {
		throw ex;
	}
};
export const activityStatusAction = async (id) => {
	try {
		const result = await api.get(`/s2/points/status/${id}`, null);
		if (result.data) {
			return result.data;
		} else {
			return false;
		}
	} catch (ex) {
		throw ex;
	}
};
export const getCampaignSubsetData = (data) => {
	return api.post(`/s1/campaign/subset`, data);
};
