import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import { pointsUpdate } from "../../redux/actions";

const SurveyMonkeyQuiz = (props) => {
	const activityData = props.activityData;
	const setLoading = props.setLoading;
	let { id } = useParams();
	const [serveyStatus, setServeyStatus] = useState({ status: false, activityEventId: "" });
	const [serveyUrl, setServeyUrl] = useState(null);
	useEffect(() => {
		setLoading(true);
		let data = {
			reference: id,
			state: "started",
		};
		props
			.pointsUpdate(data, "post")
			.then((responseData) => {
				if (responseData["data"]) {
					setServeyUrl(activityData["data"].replace("[id_value]", responseData.data.id));
					setServeyStatus(Object.assign({}, serveyStatus, { status: true, activityEventId: responseData.data.id }));
				} else {
					setServeyStatus(Object.assign({}, serveyStatus, { status: false, activityEventId: "none" }));
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log("Response Error:", JSON.stringify(err));
				setServeyStatus(Object.assign({}, serveyStatus, { status: false, activityEventId: "none" }));
				setLoading(false);
			});
		// eslint-disable-next-line
	}, []);
	const iframeLoader = (e) => {
		setLoading(false);
	};
	return (
		<>
			<div className="portal-point-list-container">
				<Container fluid>
					<div className="label">
						<Link id="headerParentLink" className="parent-link" to="/earnpoints">
							<Trans>Earn Points</Trans>
						</Link>{" "}
						<b>{activityData["title"] ? activityData.title : ""}</b>
					</div>
					<Container fluid>
						<Row xs={1} md={1} lg={1}>
							<b className="uppercase">
								<span className="">
									<Trans>Complete this</Trans>
								</span>{" "}
								{activityData["title"] ? activityData.title : ""} <Trans>and get</Trans> {activityData["points"] ? activityData.points : 0} <Trans>Points</Trans>
							</b>
						</Row>
						<Row xs={1} md={1} lg={1}>
							<p className="description">{activityData["description"] ? activityData.description : ""}</p>
						</Row>
					</Container>
				</Container>
				<Container fluid>
					{serveyStatus.activityEventId === "" ? (
						<div className="survey-message-div">
							<h1 className="alignCenter">
								<Trans>Loading</Trans>..........
							</h1>
						</div>
					) : (
						<>
							{serveyStatus.activityEventId === "none" ? (
								<div className="survey-message-div">
									<h3 className="alignCenter">
										<Trans>Sorry</Trans>!
									</h3>
									<h3 className="alignCenter">
										<Trans>You have already earned the maximum number of points possible for this action</Trans>.
									</h3>
								</div>
							) : (
								<iframe title="Survey" width="100%" id="surveyMonkeyIFrame" onLoad={iframeLoader} height="400" src={serveyUrl} />
							)}
						</>
					)}
				</Container>
			</div>
		</>
	);
};
export default React.memo(connect(null, { pointsUpdate })(IsLoadingHOC(SurveyMonkeyQuiz, "Loading....")), (prevProps, nextProps) => true);
