import React from "react";
import { Container } from "react-bootstrap";
//import {footerCopyRight} from "../data";
import { useTranslation } from "react-i18next";

const CopyRight = () => {
	const { t } = useTranslation();
	return (
		<Container fluid aria-label="footerCopyRight">
			<div className="copy-text">
				<p className="copy-text">{t("copyrightText")}</p>
			</div>
			<p className="tierlogic">
				{t("Powered By ")}
				 <a href="https://www.3tierlogic.com/" target="_blank">
					<img alt="3TL logo" src="/public/assets/images/3tier-logic-logo-white.png"/>
				</a> 
			</p>
		</Container>
	);
};

export default CopyRight;
