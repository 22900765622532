import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Container, Button, FormGroup, Modal } from 'react-bootstrap';
import { signup, login, preRegistrationAction } from '../../redux/actions';
import { connect } from 'react-redux';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GooglePlaces from '../../components/GooglePlaces';
//import Calendar from "react-calendar";

import Required from '../../components/Required';
import Captcha from '../../components/CaptchaClick';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { TextField, createTheme } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
var validator = require('validator');
const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3327',
        '&$error': {
          color: '#db3327',
        },
      },
    },
  },
});
const isValidDate = date => {
  // eslint-disable-next-line
  const matches = /^(\d{1,2})[/\/](\d{1,2})[/\/](\d{4})$/.exec(date);
  if (matches === null) {
    return false;
  }
  const [_, m, d, y] = matches;
  const composedDate = new Date(+y, +m - 1, +d);
  return composedDate.getDate() === +d && composedDate.getMonth() === +m - 1 && composedDate.getFullYear() === +y;
};
const Register = ({ signup, setLoading, login }) => {
  const { t } = useTranslation();
  const confige = useSelector(state => state.app.config);
  const history = useHistory();
  const [place, setPlace] = useState({});
  const query = new URLSearchParams(window.location.search);
  const referralid = query.get('refCode') ? query.get('refCode') : '';
  const source = query.get('src') ? query.get('src') : '';
  const promoCode = query.get('promoCode') ? query.get('promoCode') : '';
  let [captchaValue, setCaptchaValue] = useState(false);
  const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  const errorMessages = {
    firstName: {
      reqMsg: 'Please enter your first name.',
      invalidMsg: 'First Name must be  maximum 128  characters long and must contain alphabets and special characters only.',
    },
    lastName: {
      reqMsg: 'Please enter your last name.',
      invalidMsg: 'Last Name must be 128 characters long and must contain alphabets and special characters only.',
    },
    email: {
      reqMsg: 'Please enter a valid email address.',
      invalidMsg: 'Please enter a valid email address.',
    },
    confirmEmail: {
      reqMsg: 'Please enter a valid email address.',
      invalidMsg: 'Please enter a valid email address.',
    },
    city: {
      reqMsg: 'Please enter your city.',
      invalidMsg: 'Please enter a valid city name.',
    },
    address: {
      reqMsg: 'Please enter your address.',
      invalidMsg: 'Please enter a valid address.',
    },
    suite: {
      reqMsg: 'Please enter your SUITE/APT. NO.',
      invalidMsg: 'Please enter a valid SUITE/APT. NO.',
    },
    country: {
      reqMsg: 'Please enter your country.',
      invalidMsg: 'Please enter a valid country.',
    },
    state: {
      reqMsg: 'Please enter your state.',
      invalidMsg: 'Please enter a valid state.',
    },
    zip: {
      reqMsg: 'Please enter your zip code.',
      invalidMsg: 'Please enter a valid zip code.',
    },
    birth: {
      reqMsg: 'Please enter your valid DOB.',
      invalidMsg: 'Please enter your valid DOB.',
    },
    password: {
      reqMsg: 'Password must be at least 8 characters long, contain at least one number or special characters and have a mixture of uppercase and lowercase letters.',
      invalidMsg: 'Password must be at least 8 characters long, contain at least one number or special characters and have a mixture of uppercase and lowercase letters.',
    },
    passwordConfirm: {
      reqMsg: 'Confirm password must be at least 8 characters long, contain at least one number or special characters and have a mixture of uppercase and lowercase letters.',
      invalidMsg: 'Confirm password must be at least 8 characters long, contain at least one number or special characters and have a mixture of uppercase and lowercase letters.',
    },
  };

  /*[START:INITIALIZE_DECLARATION]*/
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    username: '',
    latitude: 0,
    longitude: 0,
    additionalInfo: {
      visitorId: '',
      requestId: '',
      agree_to_terms: false,
    },
  });
  /*[END:INITIALIZE_DECLARATION]*/
  const [validation, setValidation] = useState({});
  const [error, setError] = useState({
    firstName: '',
    email: '',
    confirmEmail: '',
    homePhone: '',
    cellPhone: '',
    lastName: '',
    username: '',
    address: '',
    country: '',
    state: '',
    suite: '',
    zip: '',
    city: '',
    shippingInstructions: '',
    receiveEmail: '',
    receiveSms: '',
    gender: '',
    password: '',
    passwordConfirm: '',
    termCondition: '',
    captcha: '',
    agree_to_terms: '',
  });
  useEffect(() => {
    let items = {};
    let oldState = {
      ...data,
      password: '',
      passwordConfirm: '',
    };
    if (confige.campaignState === 'partialExpired' || confige.submissionEnded === 'submissionExpired') {
      history.push('/');
      toast.error(`Sorry! You are not allowed to access this page. Promotion is ended.`);
    }
    setData(oldState);
    if (confige.uiConfig) {
      confige.uiConfig.userFields.forEach(element => {
        items[element.name] = element.validation;
        if (element.name === 'email') {
          items['confirmEmail'] = element.validation;
        }
      });
      //console.log("password", confige.uiConfig.passwordRule);
      items.password = confige.uiConfig.passwordRule;
      items.passwordConfirm = confige.uiConfig.passwordRule;
      if (confige.fingerprinting) {
        if (confige.fingerprinting.enable) {
          const fpPromise = FingerprintJS.load({
            apiKey: confige.fingerprinting.clientKey,
          });
          // Get the visitor identifier when you need it.
          fpPromise
            .then(fp => fp.get())
            .then(result => {
              let fdata = JSON.parse(JSON.stringify(data));
              fdata.additionalInfo.visitorId = result.visitorId;
              fdata.additionalInfo.requestId = result.requestId;
              setData(fdata);
            });
        }
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.registrationCaptcha) {
        setCaptchaEnable(true);
      }
    }
    setValidation(items);
  }, [confige, confige.uiConfig]);
  const handleChange = e => {
    const { name, value } = e.target;
    //console.log(name, validation);
    let errorMessage = '';
    let validationArray = validation[name];
    let check = false;
    if (name === 'agree_to_terms') {
      let d = JSON.parse(JSON.stringify(data));
      d.additionalInfo.agree_to_terms = e.target.checked;
      setData(d);
      return true;
    }
    for (let key in validationArray) {
      if (key === 'minLength') {
        if (value.length < validationArray[key] && value.length !== 0) {
          errorMessage = errorMessages[name]
            ? errorMessages[name].invalidMsg
            : t('Should not be less then', {
                count: validationArray[key],
              });
          setError({
            ...error,
            [name]: errorMessage,
          });
          check = true;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          errorMessage = errorMessages[name]
            ? errorMessages[name].invalidMsg
            : t('Should not be greater then', {
                count: validationArray[key],
              });
          setError({
            ...error,
            [name]: errorMessage,
          });
          check = true;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t('Should not be empty');
          setError({
            ...error,
            [name]: errorMessage,
          });
          check = true;
        }
      }
      if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
        errorMessage = t('Should be contain number');
        setError({
          ...error,
          [name]: errorMessage,
        });
        check = true;
      }
      if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
        errorMessage = t('Should be contain alphabets');
        setError({
          ...error,
          [name]: errorMessage,
        });
        check = true;
      }
      if (key === 'email' && validationArray[key]) {
        if (!validator.isEmail(value)) {
          errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t('Please enter a valid email');
          setError({
            ...error,
            [name]: errorMessage,
          });
          check = true;
        }
      } else if (key === 'hasNumeric' && (name === 'firstName' || name === 'lastName') && !validationArray[key] && value.search(/[0-9]/) >= 0) {
        errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t('Should not be contain number');
        setError({
          ...error,
          [name]: errorMessage,
        });
        check = true;
      } else if (key === 'hasNumeric' && (name === 'firstName' || name === 'lastName') && validationArray[key] && value.search(/[0-9]/) < 0) {
        errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t('Should be contain number');
        setError({
          ...error,
          [name]: errorMessage,
        });
        check = true;
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = t('Should have atleast one Upper Case latter');
          setError({
            ...error,
            [name]: errorMessage,
          });
          check = true;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key]) {
        if (value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = t('Should have atleast one Lower Case latter');
          setError({
            ...error,
            [name]: errorMessage,
          });
          check = true;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          errorMessage = t('Should have atleast one special letter');
          setError({
            ...error,
            [name]: errorMessage,
          });
          check = true;
        }
      }

      if (name === 'passwordConfirm') {
        if (data.password !== value) {
          setError({
            ...error,
            passwordConfirm: t('Password and confirm password should be same'),
          });
        }
      }
    }

    if (!check) {
      setError({
        ...error,
        [name]: '',
      });
    }
    setData({
      ...data,
      [name]: value,
    });
  };
  useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(error));
    if (captchaValue) {
      errorArray['captcha'] = '';
    }
    setError(errorArray);
  }, [captchaValue]);
  useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(error));
    if (data.additionalInfo.agree_to_terms) {
      errorArray['agree_to_terms'] = '';
    }
    setError(errorArray);
  }, [data.additionalInfo.agree_to_terms]);
  const onSubmit = async () => {
    let errorArray = {};
    for (let name in data) {
      let value = data[name];
      let validationArray = validation[name];
      let errorMessage = '';
      if (name === 'birth' && isNaN(new Date(value)) && value.length !== 0) {
        if (!isValidDate(value)) {
          errorMessage = 'Enter a valid date in MM/DD/YYYY format.';
        } else {
          errorMessage = errorMessages.birth ? errorMessages.birth.invalidMsg : `${t('Invalid')} ${t('Birth Date')}`;
        }
      } else if (name === 'birth') {
        errorMessage = '';
      }
      for (let key in validationArray) {
        if (key === 'email' && validationArray[key] && !validator.isEmail(value)) {
          errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t('Please enter a valid email');
        } else if (key === 'minLength' && value.length < validationArray[key] && value.length !== 0) {
          errorMessage = errorMessages[name]
            ? errorMessages[name].invalidMsg
            : t('Should not be less then', {
                count: validationArray[key],
              });
        } else if (key === 'maxLength' && value.length > validationArray[key]) {
          errorMessage = errorMessages[name]
            ? errorMessages[name].invalidMsg
            : t('Should not be greater then', {
                count: validationArray[key],
              });
        } else if (key === 'hasNumeric' && (name === 'firstName' || name === 'lastName') && !validationArray[key] && value.search(/[0-9]/) >= 0) {
          errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t('Should not be contain number');
        } else if (key === 'hasNumeric' && (name === 'firstName' || name === 'lastName') && validationArray[key] && value.search(/[0-9]/) <= 0) {
          errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t('Should be contain number');
        } else if (key === 'mandatory' && validationArray[key] && !value.length) {
          errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t('Should not be empty');
        } else if (key === 'upperCaseReq' && value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = t('Should have atleast one Upper Case latter');
        } else if (key === 'lowerCaseReq' && value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = t('Should have atleast one Lower Case latter');
        } else if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
          errorMessage = t('Should be contain number');
        } else if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
          errorMessage = t('Should be contain alphabets');
        } else if (key === 'specialCharReq' && value.length !== 0) {
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (!format.test(value)) {
            errorMessage = t('Should have atleast one special letter');
          }
        }
      }
      errorArray[name] = errorMessage;
      if (name === 'password' || name === 'passwordConfirm') {
        if (data['password'] !== data['passwordConfirm']) {
          errorMessage = t('Password and confirm password should be same.');
        }
        errorArray['passwordConfirm'] = errorMessage;
      }
      if (name === 'confirmEmail') {
        if (data['email'] !== data['confirmEmail']) {
          errorMessage = t('Email and confirm email should be same.');
        }
        errorArray['confirmEmail'] = errorMessage;
      }
    }
    if (!captchaValue && captchaEnable) {
      errorArray['captcha'] = t('Please select captcha.');
    }
    if (!data.additionalInfo.agree_to_terms) {
      errorArray['agree_to_terms'] = t('Please review the above and indicate your agreement if you wish to proceed.');
    }
    setError(errorArray);
    let check = false;
    for (let key in errorArray) {
      if (errorArray[key]) {
        check = true;
      }
    }
    if (!captchaValue && captchaEnable) {
      return false;
    }
    console.log(errorArray);
    const newData = { ...data };
    if (captchaValue !== 'none') {
      newData['captchaVal'] = captchaValue;
    }
    delete newData.passwordConfirm;
    if (!check) {
      if (newData.birth) {
        let datevalue = new Date(newData.birth);
        const dd = String(datevalue.getDate()).padStart(2, '0');
        const mm = String(datevalue.getMonth() + 1).padStart(2, '0');
        const yyyy = datevalue.getFullYear();
        const formatedDate = yyyy + '-' + mm + '-' + dd;
        newData.birth = formatedDate;
      }
      if (!newData.hasOwnProperty('additionalInfo')) {
        newData['additionalInfo'] = {};
      }
      if (promoCode) {
        newData['additionalInfo']['promoCode'] = promoCode;
      }
      if (source) {
        newData['additionalInfo']['source'] = source;
      }
      setLoading(true);
      try {
        let signupCall = true;
        if (confige.usePreRegistration) {
          signupCall = false;
          let preRegistrationResp = await preRegistrationAction(newData);
          if (preRegistrationResp.data) {
            if (preRegistrationResp.data.valid) {
              signupCall = true;
              newData.additionalInfo.token = preRegistrationResp.data.token;
            } else {
              toast.error(preRegistrationResp.data.errorReason);
              setLoading(false);
              setResetCaptchaCnt(resetCaptchaCnt + 1);
              setCaptchaValue(false);
            }
          } else {
            toast.error(t('Something went wrong'));
            setLoading(false);
            setResetCaptchaCnt(resetCaptchaCnt + 1);
            setCaptchaValue(false);
          }
        }
        if (signupCall) {
          let signupResp = await signup(newData, referralid)
            .then(resp => {
              console.log('upload page log');
              toast.success('Thank you! You have been registered for the promotion.');
              history.push('/upload-receipt');
              window.scroll({
                top: 0,
                left: 100,
                behavior: 'smooth',
              });
              setLoading(false);
            })
            .catch(err => {
              console.log('upload page error');
              toast.success(signupResp);
              history.push(`/register${window.location.search}`);
              window.scroll({
                top: 0,
                left: 100,
                behavior: 'smooth',
              });
              setLoading(false);
            });
        }
      } catch (error) {
        setResetCaptchaCnt(resetCaptchaCnt + 1);
        setCaptchaValue(false);
        console.log('errror', error);
        setLoading(false);
        if (Array.isArray(error)) {
          error.map(item => {
            toast.error(item.message);
          });
        } else {
          toast.error(t('Something went wrong'));
        }
      }
    }
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let curStateData = Object.assign({}, data);
      let curErrData = Object.assign({}, error);
      if (curStateData.hasOwnProperty('address')) {
        curStateData.address = place.address;
        curErrData.address = place.address ? '' : curErrData.address;
      }
      if (curStateData.hasOwnProperty('country')) {
        curStateData.country = place.country;
      }
      if (curStateData.hasOwnProperty('state')) {
        curStateData.state = place.state;
        curErrData.state = place.state ? '' : curErrData.state;
      }
      if (curStateData.hasOwnProperty('city')) {
        curStateData.city = place.city;
        curErrData.city = place.city ? '' : curErrData.city;
      }
      if (curStateData.hasOwnProperty('suite')) {
        curStateData.suite = place.street;
        curErrData.street = place.street ? '' : curErrData.street;
      }
      if (curStateData.hasOwnProperty('zip')) {
        curStateData.zip = place.zip;
        curErrData.zip = place.city ? '' : curErrData.zip;
      }
      if (curStateData.hasOwnProperty('latitude')) {
        curStateData.latitude = place.latitude;
      }
      if (curStateData.hasOwnProperty('longitude')) {
        curStateData.longitude = place.longitude;
      }
      setError(curErrData);
      setData(curStateData);
    }
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  return (
    <main id="main">
      <Container className="reg-page register-page">
        <MuiThemeProvider theme={formLabelsTheme}>
          <Card>
            <form aria-label="create account">
              <h1 className="text-center mt-4 mb-3">
                {/* {t("Register")} */}
                Register Now
              </h1>
              <p className="text-center mb-5">Once you register below, you will be able to upload your receipt.</p>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    {/*[START:FIRSTNAME]*/}
                    <TextField
                      label={'First Name'}
                      className="textfield"
                      required={validation['firstName'] && validation['firstName'].mandatory}
                      error={error.firstName ? true : false}
                      id="firstName"
                      helperText={error.firstName}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="firstName"
                      onChange={handleChange}
                      onFocus={handleChange}
                      // placeholder={"First Name"}
                      inputProps={{
                        'aria-label': 'Your Name',
                        'aria-autocomplete': 'name',
                      }}
                      // margin="normal"
                    />
                    {/*[END:FIRSTNAME]*/}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    {/*[START:LASTNAME]*/}
                    <TextField
                      label={'Last Name'}
                      className="textfield"
                      required={validation['lastName'] && validation['lastName'].mandatory}
                      error={error.lastName ? true : false}
                      helperText={error.lastName}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      data-test="register-lasttName"
                      name="lastName"
                      id="lastName"
                      onChange={handleChange}
                      onFocus={handleChange}
                      //placeholder={"Last Name"}
                      inputProps={{
                        'aria-label': 'Last Name',
                        'aria-autocomplete': 'family-name',
                      }}
                    />
                    {/*[END:LASTNAME]*/}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <TextField
                      label={t('lang_key_email')}
                      className="textfield"
                      required={validation['email'] && validation['email'].mandatory}
                      error={error.email ? true : false}
                      data-test="register-email"
                      helperText={error.email}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="email"
                      id="email"
                      onChange={handleChange}
                      onFocus={handleChange}
                      //placeholder={"Email"}
                      inputProps={{
                        'aria-label': 'Your Email',
                        'aria-autocomplete': 'email',
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <TextField
                      label={t('Confirm Email')}
                      className="textfield"
                      required={validation['confirmEmail'] && validation['confirmEmail'].mandatory}
                      error={error.confirmEmail ? true : false}
                      data-test="register-confirmEmail"
                      helperText={error.confirmEmail}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="confirmEmail"
                      id="confirmEmail"
                      onChange={handleChange}
                      onFocus={handleChange}
                      //placeholder={"Confirm Email"}
                      inputProps={{
                        'aria-label': 'Re-enter Email',
                        'aria-autocomplete': 'confirm-email',
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row md={1} sm={1} xs={1}>
                <Col className="my-3">
                  <div className="btm-1 d-flex v-align-baseline form-check">
                    <input type="checkbox" id="agree_to_terms" name="agree_to_terms" className="form-check-input" onChange={handleChange} />{' '}
                    <label htmlFor="agree_to_terms" className="form-check-label">
                      {' '}
                      Accept our{' '}
                      <a href="https://www.3tl.com/privacy" target="_blank">
                        privacy policy
                      </a>{' '}
                      and{' '}
                      <a href="/termsandconditions" target="_blank">
                        terms and conditions
                      </a>
                      .
                      <Required />
                    </label>
                  </div>
                  <span className="input-error" role="alert">
                    {error.agree_to_terms}
                  </span>
                </Col>
              </Row>
              <div className="clearfix"></div>
              {captchaEnable ? (
                <FormGroup className="captcha-center mt-0">
                  <label htmlFor="g-recaptcha-response" className="invisible" aria-hidden="true">
                    Google Captcha
                  </label>
                  <Captcha position={'left'} reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
                  <span
                    className="input-error"
                    style={{
                      marginTop: '-12px',
                    }}
                    role="alert">
                    {' '}
                    {error.captcha}
                  </span>
                </FormGroup>
              ) : (
                ''
              )}

              <Row md="1">
                <Col className="mt-3">
                  <Button type="button" id="register-submit-btn" data-test="register-submit" role="button" onClick={onSubmit} className="btn btn-primary">
                    <Trans>Register</Trans>
                  </Button>
                </Col>
              </Row>
            </form>
          </Card>
        </MuiThemeProvider>
      </Container>
    </main>
  );
};

export default connect(null, { signup, login })(IsLoadingHOC(Register, 'Wait .....'));
