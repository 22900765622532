import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import IsLoadingHOC from "../IsLoadingHOC";
const ViewOrder = (props) => {
	const [orderData, setOrderData] = useState(false);
	useEffect(() => {
		setOrderData(props.data);
	}, [props]);
	return (
		<Container>
			{orderData.transactionId && orderData ? (
				<Row>
					<Col sm={4} md={4}>
						<strong>
							<Trans>Transaction</Trans> <Trans>Number</Trans>
						</strong>
					</Col>
					<Col sm={8} md={8}>
						{orderData.transactionId}
					</Col>
				</Row>
			) : (
				""
			)}
			{orderData.transactionDate && orderData ? (
				<Row>
					<Col sm={4} md={4}>
						<strong>
							<Trans>Transaction</Trans> <Trans>Date</Trans>
						</strong>
					</Col>
					<Col sm={8} md={8}>
						{new Date(orderData.transactionDate).toLocaleString()}
					</Col>
				</Row>
			) : (
				""
			)}
			{orderData.transactionAmount && orderData ? (
				<Row>
					<Col sm={4} md={4}>
						<strong>
							<Trans>Amount</Trans>
						</strong>
					</Col>
					<Col sm={8} md={8}>
						{orderData.transactionAmount} {orderData.currency}
					</Col>
				</Row>
			) : (
				""
			)}
			<Row>
				<Col sm={4} md={4}>
					<strong>
						<Trans>OrderNo</Trans>
					</strong>
					:
				</Col>
				<Col sm={8} md={8}>
					{orderData ? (orderData.orderNo !== "" && orderData.orderNo ? orderData.orderNo : orderData.id) : ""}
				</Col>
				<Col sm={4} md={4}>
					<strong>
						<Trans>Order Date</Trans>
					</strong>
					:
				</Col>
				<Col sm={8} md={8}>
					{orderData ? new Date(orderData.orderDate).toLocaleDateString() : ""}
				</Col>
				<Col sm={4} md={4}>
					<strong>
						<Trans>Rewards</Trans>
					</strong>
					:
				</Col>
				<Col sm={8} md={8}>
					{orderData ? orderData.items[0].itemName : ""}
				</Col>
				<Col sm={4} md={4}>
					<strong>
						<Trans>Quantity</Trans>
					</strong>
					:
				</Col>
				<Col sm={8} md={8}>
					{orderData ? orderData.items[0].quantity : ""}
				</Col>
				<Col sm={4} md={4}>
					<strong>
						<Trans>Product</Trans> <Trans>Amount</Trans>
					</strong>
					:
				</Col>
				<Col sm={8} md={8}>
					{orderData ? `${orderData.items[0].itemValue} ${orderData.currency}` : ""}
				</Col>
				<Col sm={4} md={4}>
					<strong>
						<Trans>Description</Trans>
					</strong>
					:
				</Col>
				<Col sm={8} md={8}>
					{orderData ? orderData.items[0].itemDescription : ""}
				</Col>
				<Col sm={4} md={4}>
					<strong>
						<Trans>Shipping</Trans> <Trans>Amount</Trans>
					</strong>
					:
				</Col>
				<Col sm={8} md={8}>
					<strong>{orderData ? `${orderData.shippingCharge} ${orderData.currency}` : ""}</strong>
				</Col>
				<Col sm={4} md={4}>
					<strong>
						<Trans>Shipping</Trans> <Trans>lang_key_add_address</Trans>
					</strong>
					:
				</Col>
				<Col sm={8} md={8}>
					{orderData && orderData.shippingAddress ? orderData.shippingAddress.suite : ""}
					{orderData && orderData.shippingAddress && orderData.shippingAddress.address ? `, ` + orderData.shippingAddress.address : ""}
					{orderData && orderData.shippingAddress && orderData.shippingAddress.city ? `, ` + orderData.shippingAddress.city : ""}
					{orderData && orderData.shippingAddress && orderData.shippingAddress.state ? `, ` + orderData.shippingAddress.state : ""}
					{orderData && orderData.shippingAddress && orderData.shippingAddress.country ? `, ` + orderData.shippingAddress.country : ""}
					{orderData && orderData.shippingAddress && orderData.shippingAddress.zip ? `, ` + orderData.shippingAddress.zip : ""}
				</Col>
			</Row>
			<hr />
			{orderData.paymentAmount && orderData ? (
				<Row>
					<Col sm={4} md={4}>
						<strong>
							<Trans>Payable</Trans> <Trans>Amount</Trans>
						</strong>
					</Col>
					<Col sm={8} md={8}>
						<strong>{orderData ? `${orderData.paymentAmount} ${orderData.currency}` : ""}</strong>
					</Col>
				</Row>
			) : (
				""
			)}
			<hr />
			{props.children}
		</Container>
	);
};
export default IsLoadingHOC(ViewOrder, "Loading....");
