import React, { useState, useEffect } from "react";
//import Menu from "../MicrositeMenu";
import Menu from "../Menu";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
//import TopRight from "../TopRight";
import TopLeft from "../TopLeft";
import { Fragment } from "react";

const Header = ({ getManu }) => {
	const [menu, setMenu] = useState([]);

	useEffect(() => {
		getManu().then((response) => {
			setMenu(response.body.menuStructure);
		});
	}, [getManu]);

	return (
		<Fragment>
			<nav className="navbar navbar-expand-lg sticky-top navbar-light" role="navigation" aria-label="Main navigation">
				<TopLeft />
				<Menu data={menu} />
				{/* <TopRight/> */}
			</nav>
		</Fragment>
	);
};

export default connect(null, { getManu })(Header);
