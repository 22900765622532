import React from "react";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions";
const FaqScreen = (props) => {
	return (
		<>
			<FrequentlyAskedQuestions />
		</>
	);
};

export default FaqScreen;
