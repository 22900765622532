import React from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import Logout from "./Logout";
import { useTranslation } from "react-i18next";

const TopRight = (props) => {
	const { t } = useTranslation();
	const user = useSelector(({ user }) => user);
	return (
		<Fragment>
			{user.accessToken ? (
				<li className="nav-item ">
					{/*[START:LOGOUT]*/}
					<Logout />
					{/*[END:LOGOUT]*/}
				</li>
			) : (
				<li className="nav-item ">
					{/*[START:LOGIN]*/}
					<a id="href-login" className="nav-link nav-link-custom" href="/login">
						{t("Login")}{" "}
					</a>
					{/*[END:LOGIN]*/}
				</li>
			)}
		</Fragment>
	);
};

export default TopRight;
