import React, { useEffect, useState } from "react";
import { Modal, CloseButton, Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import { notificationStatusUpdateAction, notificationGetAction } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
const DynamicPopupMessage = (props) => {
	const location = useLocation();
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const notificationPopupMessageList = useSelector(({ app }) => app.notificationPopupMessageList);
	const campaignConfig = useSelector(({ app }) => app.config);
	const [popupData, setPopupData] = useState({});
	useEffect(() => {
		if (campaignConfig.notificationUI) {
			let filterPopup = notificationPopupMessageList.filter((v) => {
				let notificationCreatedAt = new Date(v.time);
				let currentDate = new Date();
				let Difference_In_Time = currentDate.getTime() - notificationCreatedAt.getTime();
				let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
				if (!v.isRead && Difference_In_Days <= campaignConfig.notificationUI.maxAllowDays) {
					return true;
				} else {
					return false;
				}
			});
			let finalPopupMessags = [];
			if (campaignConfig.notificationUI.isRouteBased) {
				let tempArr = {};
				for (let i in filterPopup) {
					if (!tempArr[filterPopup[i].url]) {
						tempArr[filterPopup[i].url] = [];
					}
					if (tempArr[filterPopup[i].url].length < campaignConfig.notificationUI.maxNotificationShow) {
						tempArr[filterPopup[i].url].push(filterPopup[i]);
					}
				}
				for (let i in tempArr) {
					finalPopupMessags = finalPopupMessags.concat(tempArr[i]);
				}
			} else {
				finalPopupMessags = filterPopup.slice(0, campaignConfig.notificationUI.maxNotificationShow);
			}
			finalPopupMessags = finalPopupMessags.filter((v) => {
				let route = location.pathname;
				if (v.url === "" || v.url === route) {
					return true;
				} else {
					return false;
				}
			});
			if (finalPopupMessags.length > 0) {
				setPopupData(finalPopupMessags[0]);
				handleShow();
			}
		}
		// eslint-disable-next-line
	}, [notificationPopupMessageList, location]);
	const handleClose = () => {
		setShow(false);
		let requestData = { isRead: true };
		notificationStatusUpdateAction(popupData.uuid, requestData)
			.then((res) => {
				dispatch(notificationGetAction());
			})
			.catch((err) => {
				console.log("error", err);
			});
	};
	const handleShow = () => {
		setShow(true);
	};
	return (
		<Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header>
				<Modal.Title>
					<Trans>Notification</Trans>
				</Modal.Title>
				<CloseButton onClick={handleClose} />
			</Modal.Header>
			<Modal.Body dangerouslySetInnerHTML={{ __html: popupData.shortMsg }} />

			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					<Trans>Close</Trans>
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DynamicPopupMessage;
